import React, { useEffect, useRef } from 'react';
import { Animated, StyleSheet, View } from 'react-native';
import colors from '../constants/colors';

const CARD_MARGIN = 8;

export default function PlaceholderReviewCard() {
    const fadeAnim = useRef(new Animated.Value(0.3)).current;

    useEffect(() => {
        Animated.loop(
            Animated.sequence([
                Animated.timing(fadeAnim, {
                    toValue: 1,
                    duration: 1000,
                    useNativeDriver: false,
                }),
                Animated.timing(fadeAnim, {
                    toValue: 0.3,
                    duration: 1000,
                    useNativeDriver: false,
                }),
            ]),
        ).start();
    }, [fadeAnim]);

    return (
        <Animated.View style={[styles.card, { opacity: fadeAnim }]}>
            <View style={styles.header}>
                <View style={styles.nameDate}>
                    <View style={styles.name} />
                    <View style={styles.date} />
                </View>
                <View style={styles.rating} />
            </View>
            <View style={styles.categoryReview} />
            <View style={styles.categoryReview} />
            <View style={styles.categoryReview} />
            <View style={styles.categoryReview} />
        </Animated.View>
    );
}

const styles = StyleSheet.create({
    card: {
        flex: 1,
        margin: CARD_MARGIN / 2,
        padding: 10,
        backgroundColor: colors.white,
        borderRadius: 8,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.1,
        shadowRadius: 4,
        elevation: 3,
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 12,
    },
    nameDate: {
        flex: 1,
    },
    name: {
        height: 14,
        width: '60%',
        backgroundColor: colors.lightGrey,
        marginBottom: 4,
        borderRadius: 2,
    },
    date: {
        height: 10,
        width: '40%',
        backgroundColor: colors.lightGrey,
        borderRadius: 2,
    },
    rating: {
        height: 20,
        width: 40,
        backgroundColor: colors.lightGrey,
        borderRadius: 4,
    },
    categoryReview: {
        height: 12,
        backgroundColor: colors.lightGrey,
        marginBottom: 8,
        borderRadius: 2,
    },
});