// Navbar.js
import React, { useState, useContext, useEffect } from 'react';
import Parse from 'parse';
import { StyleSheet, Text, View, Pressable, Modal, Dimensions, ScrollView, Image } from 'react-native-web';
import { AuthContext } from '../contexts/AuthContext';
import Icon from 'react-native-vector-icons/MaterialIcons';
import colors from '../constants/colors';
import Logo from '../assets/logo.svg';

const NavbarItem = ({ label, icon, isActive, onPress }) => (
  <Pressable style={[styles.item, isActive && styles.activeItem]} onPress={onPress}>
    <Icon name={icon} size={24} color={isActive ? colors.primary : colors.mediumGrey} />
    <Text style={[styles.itemText, { color: isActive ? colors.primary : colors.mediumGrey }]}>{label}</Text>
  </Pressable>
);

export default function Navbar({ page, setPage }) {
  const { user, setUser, restaurant, setRestaurant } = useContext(AuthContext);
  const [isModalVisible, setModalVisible] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const updateScreenSize = () => {
      setIsSmallScreen(Dimensions.get('window').width < 768);
    };

    updateScreenSize();
    Dimensions.addEventListener('change', updateScreenSize);

    return () => {
      Dimensions.removeEventListener('change', updateScreenSize);
    };
  }, []);

  const onLogout = async () => {
    try {
      await Parse.User.logOut();
      setUser(null);
      setRestaurant(null);
    } catch (error) {
      console.error('Failed to log out', error);
    }
  };

  const toggleModal = () => {
    setModalVisible(!isModalVisible);
  };

  const renderNavItems = () => (
    <>
      <NavbarItem
        label="Dashboard"
        icon="dashboard"
        isActive={page === 'Dashboard'}
        onPress={() => { setPage('Dashboard'); toggleModal(); }}
      />
      <NavbarItem
        label="Reports"
        icon="list"
        isActive={page === 'Reports'}
        onPress={() => { setPage('Reports'); toggleModal(); }}
      />
      {/* <NavbarItem
        label="Menu"
        icon="restaurant-menu"
        isActive={page === 'Menu'}
        onPress={() => { setPage('Menu'); toggleModal(); }}
      />
      <NavbarItem
        label="Reviews"
        icon="star"
        isActive={page === 'Reviews'}
        onPress={() => { setPage('Reviews'); toggleModal(); }}
      />
      <NavbarItem
        label="Dispatchers"
        icon="local-shipping"
        isActive={page === 'Dispatchers'}
        onPress={() => { setPage('Dispatchers'); toggleModal(); }}
      />
      <NavbarItem
        label="Marketing"
        icon="campaign"
        isActive={page === 'Marketing'}
        onPress={() => { setPage('Marketing'); toggleModal(); }}
      />
      <NavbarItem
        label="Support"
        icon="chat"
        isActive={page === 'Support'}
        onPress={() => { setPage('Support'); toggleModal(); }}
      /> */}
      {/* <NavbarItem
        label="Store Management"
        icon="store"
        isActive={page === 'StoreManagement'}
        onPress={() => { setPage('StoreManagement'); toggleModal(); }}
      /> */}
      {/* <NavbarItem
        label="Bank Details"
        icon="account-balance"
        isActive={page === 'BankDetails'}
        onPress={() => { setPage('BankDetails'); toggleModal(); }}
      /> */}
      {/* <NavbarItem
        label="Payments"
        icon="payments"
        isActive={page === 'Payments'}
        onPress={() => { setPage('Payments'); toggleModal(); }}
      /> */}
      <NavbarItem
        label="Settings"
        icon="settings"
        isActive={page === 'Settings'}
        onPress={() => { setPage('Settings'); toggleModal(); }}
      />
    </>
  );

  return (
    <View style={styles.container}>
      <View style={styles.navbar}>
        <img src={Logo} style={styles.logo} alt="Logo" />
        <Pressable onPress={toggleModal} style={styles.hamburgerButton}>
          <Icon name="menu" size={24} color={colors.darkGrey} />
        </Pressable>
      </View>
      <Modal
        animationType="slide"
        transparent={true}
        visible={isModalVisible}
        onRequestClose={toggleModal}
      >
        <View style={styles.modalView}>
          <View style={styles.modalHeader}>
            <Text style={styles.modalTitle}>Menu</Text>
            <Pressable onPress={toggleModal} style={styles.closeButton}>
              <Icon name="close" size={24} color={colors.darkGrey} />
            </Pressable>
          </View>
          <ScrollView style={styles.navItems}>
            {renderNavItems()}
          </ScrollView>
          <Pressable style={styles.logoutButton} onPress={onLogout}>
            <Icon name="exit-to-app" size={24} color={colors.white} />
            <Text style={styles.logoutText}>Logout</Text>
          </Pressable>
        </View>
      </Modal>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  navbar: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 10,
    height: 60,
    backgroundColor: colors.white,
    borderBottomWidth: 1,
    borderBottomColor: colors.lightGrey,
  },
  logoText: {
    fontSize: 24,
    fontWeight: 'bold',
    color: colors.primary,
  },
  hamburgerButton: {
    padding: 10,
  },
  modalView: {
    flex: 1,
    backgroundColor: colors.white,
  },
  modalHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 20,
    borderBottomWidth: 1,
    borderBottomColor: colors.lightGrey,
  },
  modalTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    color: colors.darkGrey,
  },
  closeButton: {
    padding: 5,
  },
  navItems: {
    flex: 1,
  },
  item: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 15,
    paddingHorizontal: 20,
  },
  activeItem: {
    backgroundColor: colors.lightBackground,
    borderLeftColor: colors.primary,
    borderLeftWidth: 3,
  },
  itemText: {
    fontSize: 16,
    marginLeft: 15,
  },
  logoutButton: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.primary,
    padding: 15,
    margin: 20,
    borderRadius: 10,
  },
  logoutText: {
    color: colors.white,
    fontWeight: 'bold',
    marginLeft: 10,
  },
  logo: {
    width: 50,
    padding: 10,
  },
});