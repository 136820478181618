import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Dimensions, ActivityIndicator, Linking } from 'react-native';
import colors from '../../constants/colors';
import { getNews } from '../../services/api/news';

// Title, Description, Button Text, and Button Link

const NewsItem = ({ title, description, buttonText, buttonLink }) => (
  <View style={styles.newsItem}>
    <Text style={styles.newsTitle}>{title}</Text>
    <Text style={styles.newsDescription} numberOfLines={2}>
      {description}
    </Text>
    <TouchableOpacity style={styles.learnMoreButton} onPress={() => Linking.openURL(buttonLink)}>
      <Text style={styles.learnMoreText}>{buttonText}</Text>
    </TouchableOpacity>
  </View>
);

const { width } = Dimensions.get('window');
const isMobile = width < 768;

const News = () => {
  const [newsItems, setNewsItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const news = await getNews();

        setNewsItems(news);
      } catch (error) {
        console.error('Error al cargar las noticias:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchNews();
  }, []);

  if (isLoading) {
    return (
      <View style={styles.loadingContainer}>
        <ActivityIndicator size="large" color={colors.primary} />
      </View>
    );
  }

  return (
    <View style={styles.container}>
      {newsItems.map((item, index) => (
        <NewsItem 
          key={item.objectId} 
          title={item.title} 
          description={item.description} 
          buttonText={item.buttonText} 
          buttonLink={item.buttonLink} 
        />
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 10,
    width: isMobile ? '100%' : '25%',
  },
  header: {
    fontSize: 18,
    fontWeight: 'bold',
    color: colors.darkGrey,
    marginBottom: 16,
  },
  newsItem: {
    marginBottom: 16,
    padding: 16,
    backgroundColor: colors.white,
    borderRadius: 10,
    shadowColor: colors.darkGrey,
    shadowOffset: { width: 3, height: 3 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 5,
  },
  newsTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    color: colors.darkGrey,
    marginBottom: 8,
  },
  newsDescription: {
    fontSize: 14,
    color: colors.mediumGrey,
    marginBottom: 8,
  },
  learnMoreButton: {
    alignSelf: 'flex-start',
    backgroundColor: colors.primary,
    paddingHorizontal: 12,
    paddingVertical: 6,
    borderRadius: 4,
  },
  learnMoreText: {
    color: colors.white,
    fontWeight: 'bold',
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default News;
