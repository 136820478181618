import React, { useState, useEffect, useContext } from 'react';
import { View, Text, StyleSheet, ActivityIndicator, TextInput, TouchableOpacity, ScrollView, Dimensions, Pressable } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import colors from '../../constants/colors';
import { getOrders } from '../../services/api/orders';
import { AuthContext } from '../../contexts/AuthContext';

const { width } = Dimensions.get('window');

const isMobile = width < 768;

const PerformanceRow = ({ plate, timesOrdered, itemPrice, totalRevenue }) => (
  <View style={styles.row}>
    <Text style={[styles.cell, styles.plateCell]}>{plate}</Text>
    <Text style={styles.cell}>{timesOrdered}</Text>
    <Text style={styles.cell}>${itemPrice}</Text>
    <Text style={styles.cell}>${totalRevenue}</Text>
  </View>
);

export default function PerformanceMetrics({ startDate, endDate }) {
  const [performanceData, setPerformanceData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortColumn, setSortColumn] = useState('totalRevenue');
  const [sortDirection, setSortDirection] = useState('desc');
  const { restaurant } = useContext(AuthContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(15);

  useEffect(() => {
    if (restaurant && startDate && endDate) {
      fetchPerformanceData();
    }
  }, [restaurant, startDate, endDate]);

  useEffect(() => {
    handleSearch(searchQuery);
  }, [searchQuery, performanceData]);

  const fetchPerformanceData = async () => {
    try {
      setIsLoading(true);
      const orders = await getOrders(restaurant.id, startDate.toISOString(), endDate.toISOString(), [
        'objectId',
        'dishes'
      ], 1000, ['DELIVERED']);
      const processedData = processOrders(orders);
      setPerformanceData(processedData);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching performance data:', error);
      setIsLoading(false);
    }
  };

  const processOrders = (orders) => {
    const platePerformance = {};

    orders.forEach(order => {
      order.dishes.forEach(dish => {
        if (!platePerformance[dish.name]) {
          platePerformance[dish.name] = {
            timesOrdered: 0,
            totalRevenue: 0,
            preparationTime: '15 Min', // Assuming a fixed prep time for now
            itemPrice: dish.price
          };
        }
        platePerformance[dish.name].timesOrdered += dish.count;
        platePerformance[dish.name].totalRevenue += dish.price * dish.count;
      });
    });

    return Object.entries(platePerformance)
      .map(([plate, data]) => ({
        plate,
        ...data,
        totalRevenue: parseFloat(data.totalRevenue.toFixed(2))
      }))
      .sort((a, b) => b.totalRevenue - a.totalRevenue)
      .slice(0, 20);
  };

  const handleSearch = (query) => {
    const lowercaseQuery = query.toLowerCase();
    const filtered = performanceData.filter(item =>
      item.plate.toLowerCase().includes(lowercaseQuery)
    );
    setFilteredData(filtered);
  };

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('desc');
    }
  };

  const sortedData = [...filteredData].sort((a, b) => {
    if (a[sortColumn] < b[sortColumn]) return sortDirection === 'asc' ? -1 : 1;
    if (a[sortColumn] > b[sortColumn]) return sortDirection === 'asc' ? 1 : -1;
    return 0;
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(sortedData.length / itemsPerPage);

  const goToNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleItemsPerPageChange = (event) => {
    const newItemsPerPage = parseInt(event.target.value, 10);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1); // Reset to first page when changing items per page
  };

  const renderSortIcon = (column) => {
    if (column === sortColumn) {
      return <Icon name={sortDirection === 'asc' ? 'arrow-upward' : 'arrow-downward'} size={16} color={colors.primary} />;
    }
    return <Icon name="unfold-more" size={16} color={colors.mediumGrey} />;
  };

  if (isLoading) {
    return (
      <View style={styles.loadingContainer}>
        <ActivityIndicator size="large" color={colors.primary} />
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <View>
        <View style={styles.headerContainer}>
          <Text style={styles.title}>Performance Metrics</Text>
          <View style={styles.searchContainer}>
            <TextInput
              style={styles.searchInput}
              placeholder="Search"
              onChangeText={setSearchQuery}
              value={searchQuery}
            />
            <TouchableOpacity style={styles.searchIcon} onPress={() => handleSearch(searchQuery)}>
              <Icon name="search" size={24} color={colors.black} />
            </TouchableOpacity>
          </View>
        </View>
      </View>
      <ScrollView horizontal={isMobile} style={styles.tableContainer}>
        <View style={styles.tableContent}>
          <View style={styles.tableHeader}>
            <Text style={[styles.headerCell, styles.plateCell]}>
              Plates
            </Text>
            <TouchableOpacity style={styles.headerCell} onPress={() => handleSort('timesOrdered')}>
              <Text>Times Ordered</Text>
              {renderSortIcon('timesOrdered')}
            </TouchableOpacity>
            <TouchableOpacity style={styles.headerCell} onPress={() => handleSort('itemPrice')}>
              <Text>Item Price</Text>
              {renderSortIcon('itemPrice')}
            </TouchableOpacity>
            <TouchableOpacity style={styles.headerCell} onPress={() => handleSort('totalRevenue')}>
              <Text>Total Revenue</Text>
              {renderSortIcon('totalRevenue')}
            </TouchableOpacity>
          </View>
          {currentItems.map((item, index) => (
            <PerformanceRow
              key={index}
              plate={item.plate}
              timesOrdered={item.timesOrdered}
              itemPrice={item.itemPrice}
              totalRevenue={item.totalRevenue}
            />
          ))}
        </View>
      </ScrollView>
      <View style={styles.footer}>
        <View style={styles.rowsPerPageContainer}>
          <Text>Rows per page:</Text>
          <select
            value={itemsPerPage}
            onChange={handleItemsPerPageChange}
            style={styles.select}
          >
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </View>
        <Text>{performanceData.length > 0 ? `${indexOfFirstItem + 1}-${Math.min(indexOfLastItem, performanceData.length)} of ${performanceData.length}` : '0-0 of 0'}</Text>
        <Pressable style={styles.iconButton} onPress={goToPreviousPage} disabled={currentPage === 1}>
          <Icon name="chevron-left" size={24} color={currentPage === 1 ? colors.lightGrey : colors.darkGrey} />
        </Pressable>
        <Pressable style={styles.iconButton} onPress={goToNextPage} disabled={currentPage === totalPages}>
          <Icon name="chevron-right" size={24} color={currentPage === totalPages ? colors.lightGrey : colors.darkGrey} />
        </Pressable>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
    borderRadius: 10,
    padding: 15,
    shadowColor: colors.darkGrey,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 3,
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 15,
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    color: colors.lightGrey,
  },
  tableContainer: {
    flex: 1,
  },
  tableContent: {
    minWidth: '100%',
  },
  tableHeader: {
    flexDirection: 'row',
    backgroundColor: colors.lightGrey,
    paddingVertical: 10,
  },
  headerCell: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 10,
    minWidth: 100,
  },
  plateCell: {
    flex: 2,
    minWidth: 150,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  searchContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#F5F5F5',
    width: '60%',
    borderBottomWidth: 1,
    borderBottomColor: '#dfeaf2',
    borderTopRightRadius: 10
  },
  searchInput: {
    padding: 10,
    fontSize: 16,
    flex: 1,
    width: '80%',
  },
  searchIcon: {
    padding: 10,
  },
  row: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: colors.lightGrey,
    paddingVertical: 10,
  },
  cell: {
    flex: 1,
    paddingHorizontal: 10,
    minWidth: 100,
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: 16,
  },
  rowsPerPageContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 16,
  },
  iconButton: {
    padding: 8,
  }
});