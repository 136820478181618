import Parse from 'parse';

export const getNews = async () => {
  try {
    const News = Parse.Object.extend('News');
    const query = new Parse.Query(News);
    
    // Aquí puedes agregar más condiciones a la consulta si es necesario
    // Por ejemplo, ordenar por fecha de creación descendente
    query.descending('createdAt');
    
    // Limitar el número de resultados si es necesario
    query.limit(3);

    const results = await query.find();
    
    // Convertir los objetos Parse a objetos JavaScript planos
    return results.map(news => news.toJSON());
  } catch (error) {
    console.error('Error al obtener las noticias:', error);
    throw error;
  }
};
