// orders.js
import Parse from 'parse';

// Base function for querying orders
const queryOrders = async (restaurantId, startDate, endDate, fields, limit, status, additionalSetup = () => {}) => {
    const Order = Parse.Object.extend('Order');
    const query = new Parse.Query(Order);

    query.equalTo('restaurant', Parse.Object.extend('Restaurant').createWithoutData(restaurantId));
    
    if (startDate) {
        query.greaterThanOrEqualTo('createdAt', new Date(startDate));
    }
    
    if (endDate) {
        query.lessThanOrEqualTo('createdAt', new Date(endDate));
    }

    query.select(...fields);

    query.include('user');
    
    query.include('restaurant');
    
    query.include('driver');
    
    if (status.length > 0) {
        query.containedIn('status', status);
    }

    query.limit(limit);
    
    query.descending('createdAt');

    // Apply any additional setup
    additionalSetup(query);

    try {
        const results = await query.find();
        return results.map(order => order.toJSON());
    } catch (error) {
        console.error('Error fetching orders:', error);
        throw error;
    }
};

export const getOrders = async (
    restaurantId, 
    startDate, 
    endDate, 
    fields = ['objectId', 'taxData', 'dishes', 'restaurant', 'user', 'driver', 'orderNumber', 'total', 'status', 'contactPhone', 'subtotals', 'createdAt', 'updatedAt', 'restaurantSubtotals'],
    limit = 1000,
    status = []
) => {
    return queryOrders(restaurantId, startDate, endDate, fields, limit, status);
};

export const getReviews = async (
    restaurantId,
    startDate,
    endDate,
    fields = ['objectId', 'review', 'user.full_name', 'createdAt'],
    limit = 100
) => {
    try {
        const response = await Parse.Cloud.run('fetchReviewsByFilter', {
            page: 0,
            limit: 10000,
            filter: {
                restaurantId,
                startDate,
                endDate
            }
        });
        return response.items;
    } catch (error) {
        console.error('Error fetching reviews:', error);
        throw error;
    }
};

export const restaurantLogin = async (username, password) => {
    const response = await Parse.Cloud.run('restaurantLogin', { username, password });
    return response;
};


export const getSalesData = async (restaurantId, startDate, endDate, fields = ['date', 'total']) => {
  const Order = Parse.Object.extend('Order');
  const query = new Parse.Query(Order);
  
  query.equalTo('restaurant', Parse.Object.extend('Restaurant').createWithoutData(restaurantId));
  query.greaterThanOrEqualTo('createdAt', new Date(startDate));
  query.lessThanOrEqualTo('createdAt', new Date(endDate));
  query.equalTo('status', 'DELIVERED');
  
  query.select(...fields);
  query.limit(1000); // Adjust this based on your needs and Parse Server limitations
  
  const results = await query.find();
  
  // Process the results into daily data
//   console.log('results:', results);
  const salesData = results.map(order => ({
    // Esta línea extrae la fecha de creación del pedido, 
    // la convierte a formato ISO y luego la recorta para obtener solo la parte de la fecha (AAAA-MM-DD)
    date: order.get('createdAt').toISOString().split('T')[0], // YYYY-MM-DD
    // total: order.get('total') / 100 // Convert cents to dollars
    total: order.get('restaurantSubtotals')
  }));
  
  // Sort the data by date
  salesData.sort((a, b) => new Date(a.date) - new Date(b.date));
  
  return salesData;
};

export const subscribeToNewOrders = (restaurantId, onNewOrder) => {
    const Order = Parse.Object.extend('Order');
    const Restaurant = Parse.Object.extend('Restaurant');
    const restaurant = new Restaurant();
    restaurant.id = restaurantId;
  
    const query = new Parse.Query(Order);
    query.equalTo('restaurant', restaurant);
  
    const liveQueryClient = new Parse.LiveQueryClient({
        applicationId: 'rifBVbTe6ZkO8DXefo8onAavO3mgmb7nzqai55uI',
        serverURL: 'wss://pg-app-fpknvyifctuxd3evb1elz0acvdijxe.scalabl.cloud/1/',
        javascriptKey: 'z18zu1sVDQCTl7NtzKmfCvEZQVFhyEWQwmvJQR8q',
    });
    liveQueryClient.open();

    // if subscription succeeds console log success message
    liveQueryClient.on('open', () => {
        console.log('subscription opened');
    });
    const subscription = liveQueryClient.subscribe(query);
    subscription.on('create', onNewOrder);
  
    
    return subscription; // You can use this to unsubscribe later if needed
};

export const exportTaxSummaryToCSV = (orders, startDate, endDate) => {
  try {
    let csvContent = "data:text/csv;charset=utf-8,";
    csvContent += "Order Number,Municipality,State Taxes6,State Taxes105,State Taxes Other,Total\n";

    orders.forEach(order => {
      const orderDate = new Date(order.createdAt.iso).toLocaleDateString();
      csvContent += `${order.orderNumber},${order.taxData.foodTaxes.municipality.total},${order.taxData.foodTaxes.state.stateTaxes6},${order.taxData.foodTaxes.state.stateTaxes105},${order.taxData.foodTaxes.state.stateTaxesOther},${order.taxData.foodTaxes.total}\n`;
    });

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `tax_summary_${startDate.split('T')[0]}_${endDate.split('T')[0]}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    return true;
  } catch (error) {
    console.error('Error exporting tax summary to CSV:', error);
    throw error;
  }
};