import React, { useState, useEffect, useContext } from 'react';
import { StyleSheet, Text, View, Pressable, FlatList, TextInput, TouchableOpacity, Platform, ScrollView, Dimensions } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import { Ionicons } from '@expo/vector-icons';
import colors from '../constants/colors';
import Badge from './Badge';
import { getOrders, exportTaxSummaryToCSV } from '../services/api/orders';
import { AuthContext } from '../contexts/AuthContext';
import PlaceholderOrderItem from './PlaceholderOrderItem';
// import * as FileSystem from 'expo-file-system';
import OrderDetailModal from './OrderDetailModal';
import CustomDateRangePicker from './dashboard/CustomDatePicker/CustomDateRangePicker';
import { DateContext } from '../contexts/DateContext';

// import moment from 'moment';

const { width } = Dimensions.get('window');

const isMobile = width < 768;

const badgeTextColors = {
  paid: 'rgba(41, 98, 255, 1)',
  received: 'rgba(41, 98, 255, 1)',
  pending: 'rgba(213, 0, 0, 1)',
  finished: 'rgba(71, 237, 116, 1)',
  pickedUp: 'rgba(170, 0, 255, 1)',
  cooking: 'rgba(255, 193, 7, 1)',
  delivered: 'rgba(71, 237, 116, 1)',
  refunded: 'rgb(0, 0, 0)',
  cancelled: 'rgba(213, 0, 0, 1)',
  unpaid: 'rgba(255, 193, 7, 1)',
}

export default function Orders() {
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { restaurant } = useContext(AuthContext);
  const [filteredData, setFilteredData] = useState([]);
  // const [startDate, setStartDate] = useState(null);
  // const [endDate, setEndDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoadingCsv, setIsLoadingCsv] = useState(false);
  const { startDate, endDate, updateDates } = useContext(DateContext);

  const handleDatesChange = ({ startDate, endDate }) => {
    updateDates(startDate, endDate);
  };

  const handleSearch = (query) => {
    const lowercaseQuery = query.toLowerCase();
    const filtered = orders.filter(item => {
      return item.user && item.user.full_name && item.user.full_name.toLowerCase().includes(lowercaseQuery);
    });
    setFilteredData(filtered);
  };

  useEffect(() => {
    if (restaurant && startDate && endDate) {
      fetchOrders();
    }
  }, [startDate, endDate, restaurant]);

  useEffect(() => {
    handleSearch(searchQuery);
  }, [searchQuery, orders]);

  const exportToCSV = async () => {
    try {
      // Mostrar un indicador de carga (puedes usar un estado para mostrar un spinner)
      setIsLoadingCsv(true);

      // Convertir los datos a formato CSV
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += "ID,Order No,Total,Sub Totals,Taxes,Revenue Share,Received At,Status,Dishes,Refund Amount,Refund Reason,Created At,Client Name\n";
      filteredData.forEach(item => {
        let dishes = item.dishes.map(dish => ({
          name: dish.name,
          quantity: dish.count,
          price: dish.price
        }));

        let dishString = JSON.stringify(dishes);

        dishString = dishString.replace(/,/g, ';');

        csvContent += `${item.objectId},${item.orderNumber},${(item.subtotalData.totalRestaurantSubtotal)},${item.subtotals},${item.taxData.total},${item.revShare},${item.receivedAt ? item.receivedAt.iso : ''},${item.status},"${dishString}",${item.refundAmount ? (item.refundAmount / 100) : 0},${item.refundReason ? item.refundReason : ''},${item.createdAt},${item?.user?.full_name || ''} \n`;
      });

      // Codificar el contenido CSV para URL
      const encodedUri = encodeURI(csvContent);

      // Crear un elemento de enlace temporal
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", `ventas_${startDate.toISOString().split('T')[0]}_${endDate.toISOString().split('T')[0]}.csv`);
      document.body.appendChild(link); // Necesario para Firefox

      // Descargar el archivo
      link.click();

      // Limpiar
      document.body.removeChild(link);
      setIsLoadingCsv(false);
    } catch (error) {
      console.error('Error al exportar CSV:', error);
      alert("Error al exportar los datos. Por favor, intente de nuevo.");
      setIsLoadingCsv(false);
    }
  };

  const fetchOrders = async () => {
    setIsLoading(true);
    try {
      const fetchedOrders = await getOrders(restaurant.id, startDate.toISOString(), endDate.toISOString(), [
        'objectId',
        'orderNumber',
        'total',
        'subtotals',
        'subtotalData',
        'taxData',
        'revShare',
        'receivedAt',
        'status',
        'dishes',
        'refundAmount',
        'refundReason',
        'createdAt',
        'user'
      ]);

      setOrders(fetchedOrders);

      setFilteredData(fetchedOrders);
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
    setIsLoading(false);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const goToNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const tableHead = ['Order #', 'Client', 'Sub Total', 'Taxes', 'Total', 'Date Time', 'Status'];
  const tableHeadTaxSummary = ['Order #', 'Municipality', 'State Taxes6', 'State Taxes105', 'State Taxes Other', 'Total'];

  const renderTableHeader = () => (
    <View style={styles.tableRow}>
      {tableHead.map((header, index) => (
        <Text key={index} style={[styles.headerCell, styles.cell]}>{header}</Text>
      ))}
    </View>
  );

  const renderTableHeaderTaxSummary = () => (
    <View>
      <View style={[styles.tableRow]}>
        {tableHeadTaxSummary.map((header, index) => (
          <Text key={index} style={[styles.headerCell, styles.cell]}>{header}</Text>
        ))}
      </View>
    </View>
  );

  const renderOrderRow = ({ item: order }) => (
    <TouchableOpacity
      style={[styles.tableRow, { backgroundColor: colors.white }]}
      onPress={() => {
        setSelectedOrder(order);
        setIsModalVisible(true);
      }}
    >
      <Text style={styles.cell}>{order.orderNumber}</Text>
      <Text style={styles.cell}>{order.user.full_name}</Text>
      <Text style={[styles.cell, { fontWeight: 'bold' }]}>${(order.subtotals / 100).toFixed(2)}</Text>
      <Text style={styles.cell}>${(order.taxData.total / 100).toFixed(2)}</Text>
      <Text style={[styles.cell, { fontWeight: 'bold' }]}>${(order.subtotalData.totalRestaurantSubtotal).toFixed(2)}</Text>
      <Text style={styles.cell}>{new Date(order.createdAt).toLocaleString()}</Text>
      <View style={styles.cell}>
        <Badge text={order.status} customStyles={styles[order.status.toLowerCase()]} textColor={badgeTextColors[order.status.toLowerCase()]} />
      </View>
    </TouchableOpacity>
  );

  const renderOrderRowTaxSummary = ({ item: order }) => (
    <TouchableOpacity
      style={[styles.tableRow, { backgroundColor: colors.white }]}
      onPress={() => {
        setSelectedOrder(order);
        setIsModalVisible(true);
      }}
    >
      <Text style={styles.cell}>{order.orderNumber}</Text>
      <Text style={styles.cell}>${order.taxData.foodTaxes.municipality.total}</Text>
      <Text style={styles.cell}>${order.taxData.foodTaxes.state.stateTaxes6}</Text>
      <Text style={styles.cell}>${order.taxData.foodTaxes.state.stateTaxes105}</Text>
      <Text style={styles.cell}>${order.taxData.foodTaxes.state.stateTaxesOther}</Text>
      <Text style={[styles.cell, { fontWeight: 'bold' }]}>${order.taxData.foodTaxes.total}</Text>
    </TouchableOpacity>
  );

  const handleItemsPerPageChange = (event) => {
    const newItemsPerPage = parseInt(event.target.value, 10);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1); // Reset to first page when changing items per page
  };

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Text style={styles.headerText}>Reports</Text>
        <View style={styles.headerRight}>
          <View style={styles.searchContainer}>
            <TextInput
              style={styles.searchInput}
              placeholder="Search"
              onChangeText={setSearchQuery}
              value={searchQuery}
            />
            <TouchableOpacity style={styles.searchIcon} onPress={() => handleSearch(searchQuery)}>
              <Icon name="search" size={24} color={colors.black} />
            </TouchableOpacity>
          </View>
          <View style={styles.dateRangeContainer}>
            <CustomDateRangePicker
              startDate={startDate}
              endDate={endDate}
              onDatesChange={handleDatesChange}
            />
          </View>
          <TouchableOpacity style={styles.exportButton} onPress={exportToCSV}>
            {isMobile ? (
              <Ionicons name={isLoadingCsv ? "document-text" : "document-text-outline"} size={24} color="white" />
            ) : (
              <Text style={styles.exportButtonText}>{isLoadingCsv ? 'Exportando...' : 'Export CSV'}</Text>
            )}
          </TouchableOpacity>
        </View>
      </View>
      {isLoading ? (
        <>
          <PlaceholderOrderItem />
          <PlaceholderOrderItem />
          <PlaceholderOrderItem />
        </>
      ) : (
        <View>
          <View>
            <Text style={{ fontSize: 25, fontWeight: 'bold', color: colors.darkGrey }}>Orders</Text>
            <ScrollView horizontal={isMobile} style={styles.tableContainer}>
              <View style={styles.tableContent}>
                <FlatList
                  data={currentItems}
                  renderItem={renderOrderRow}
                  keyExtractor={(item) => item.objectId}
                  ListHeaderComponent={renderTableHeader}
                />
              </View>
            </ScrollView>
            <View style={styles.footer}>
              <View style={styles.rowsPerPageContainer}>
                <Text>Rows per page:</Text>
                <select
                  value={itemsPerPage}
                  onChange={handleItemsPerPageChange}
                  style={styles.select}
                >
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </View>
              <Text>{orders.length > 0 ? `${indexOfFirstItem + 1}-${Math.min(indexOfLastItem, orders.length)} of ${orders.length}` : '0-0 of 0'}</Text>
              <Pressable style={styles.iconButton} onPress={goToPreviousPage} disabled={currentPage === 1}>
                <Icon name="chevron-left" size={24} color={currentPage === 1 ? colors.lightGrey : colors.darkGrey} />
              </Pressable>
              <Pressable style={styles.iconButton} onPress={goToNextPage} disabled={currentPage === totalPages}>
                <Icon name="chevron-right" size={24} color={currentPage === totalPages ? colors.lightGrey : colors.darkGrey} />
              </Pressable>
            </View>
          </View>

          <View style={{ marginTop: 20 }}>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
              <Text style={{ fontSize: 25, fontWeight: 'bold', color: colors.darkGrey }}>Tax summary</Text>
              <TouchableOpacity style={styles.exportButton} onPress={() => exportTaxSummaryToCSV(orders, startDate.toISOString(), endDate.toISOString())}>
                {isMobile ? (
                  <Ionicons name={isLoadingCsv ? "document-text" : "document-text-outline"} size={24} color="white" />
                ) : (
                  <Text style={styles.exportButtonText}>{isLoadingCsv ? 'Exportando...' : 'Export CSV'}</Text>
                )}
              </TouchableOpacity>
            </View>

            <ScrollView horizontal={isMobile} style={styles.tableContainer}>
              <View style={styles.tableContent}>
                <FlatList
                  data={currentItems}
                  renderItem={renderOrderRowTaxSummary}
                  keyExtractor={(item) => item.objectId}
                  ListHeaderComponent={renderTableHeaderTaxSummary}
                />
              </View>
            </ScrollView>
            <View style={styles.footer}>
              <View style={styles.rowsPerPageContainer}>
                <Text>Rows per page:</Text>
                <select
                  value={itemsPerPage}
                  onChange={handleItemsPerPageChange}
                  style={styles.select}
                >
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </View>
              <Text>{orders.length > 0 ? `${indexOfFirstItem + 1}-${Math.min(indexOfLastItem, orders.length)} of ${orders.length}` : '0-0 of 0'}</Text>
              <Pressable style={styles.iconButton} onPress={goToPreviousPage} disabled={currentPage === 1}>
                <Icon name="chevron-left" size={24} color={currentPage === 1 ? colors.lightGrey : colors.darkGrey} />
              </Pressable>
              <Pressable style={styles.iconButton} onPress={goToNextPage} disabled={currentPage === totalPages}>
                <Icon name="chevron-right" size={24} color={currentPage === totalPages ? colors.lightGrey : colors.darkGrey} />
              </Pressable>
            </View>
          </View>
        </View>
      )}
      <OrderDetailModal
        isVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        order={selectedOrder}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.lightBackground,
    padding: 10,
  },
  header: {
    flexDirection: isMobile ? 'column' : 'row',
    justifyContent: 'space-between',
    alignItems: isMobile ? 'flex-start' : 'center',
    marginBottom: 40,
    zIndex: 1,
    paddingHorizontal: isMobile ? 0 : 30,
  },
  headerText: {
    fontSize: 24,
    fontWeight: 'bold',
    color: colors.darkGrey,
  },
  headerRight: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  searchContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#F5F5F5',
    borderRadius: 5,
    marginRight: 10,
    width: isMobile ? '100%' : 'auto',
    marginBottom: isMobile ? 10 : 0,
  },
  searchInput: {
    padding: 10,
    fontSize: 16,
    width: isMobile ? '100%' : 200,
  },
  searchIcon: {
    padding: 10,
  },
  dateRangeContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 10,
    backgroundColor: '#f5f5f5',
    padding: 5,
    borderRadius: 5,
    width: isMobile ? '80%' : 'auto',
  },
  exportButton: {
    backgroundColor: colors.primary,
    paddingHorizontal: isMobile ? 6 : 15,
    paddingVertical: isMobile ? 6 : 10,
    borderRadius: 5,
  },
  exportButtonText: {
    color: colors.white,
    fontWeight: 'bold',
  },
  tableRow: {
    flexDirection: 'row',
    // borderBottomWidth: 1,
    // borderBottomColor: colors.lightGrey,
  },
  headerCell: {
    fontWeight: 'bold',
    backgroundColor: '#F5F5F5',
    color: colors.mediumGrey,
  },
  cell: {
    flex: 1,
    padding: 10,
    textAlign: 'center',
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: 16,
  },
  rowsPerPageContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 16,
  },
  select: {
    marginLeft: 8,
    padding: 4,
    borderColor: colors.lightGrey,
    borderWidth: 1,
    borderRadius: 4,
  },
  iconButton: {
    padding: 8,
  },
  tableContainer: {
    flex: 1,
    marginTop: 20,
  },
  tableContent: {
    minWidth: '100%',
  },
  unpaid: {
    backgroundColor: 'rgba(255, 193, 7, 0.2)',
  },
  paid: {
    backgroundColor: 'rgba(41, 98, 255, 0.2)',
  },
  received: {
    backgroundColor: 'rgba(41, 98, 255, 0.2)',
  },
  pending: {
    backgroundColor: 'rgba(213, 0, 0, 0.2)',
  },
  finished: {
    backgroundColor: 'rgba(71, 237, 116, 0.2)'
  },
  pickedUp: {
    backgroundColor: 'rgba(170, 0, 255, 0.2)',
  },
  cooking: {
    backgroundColor: 'rgba(255, 193, 7, 0.2)',
  },
  delivered: {
    backgroundColor: 'rgba(71, 237, 116, 0.2)'
  },
  refunded: {
    backgroundColor: 'rgba(255, 61, 0, 0.2)',
  },
  cancelled: {
    backgroundColor: 'rgba(213, 0, 0, 0.2)',
  }
});