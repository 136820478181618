import React from 'react';
import { View, Text, Pressable, Image, StyleSheet } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import colors from '../constants/colors';

const ImageSelector = ({ image, onPress, existingImageUrl }) => {
  return (
    <View style={{justifyContent: 'center', alignItems: 'center'}}>
      <Pressable style={styles.imageContainer} onPress={onPress}>
        {image ? (
          <Image source={{ uri: `data:image/jpeg;base64,${image.base64}` }} style={styles.image} />
        ) : existingImageUrl ? (
          <Image source={{ uri: existingImageUrl }} style={styles.image} />
        ) : (
          <View style={styles.placeholderContainer}>
            <Icon name="add-a-photo" size={48} color={colors.primary} />
            <Text style={styles.placeholderText}>Agregar imagen</Text>
          </View>
        )}
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  imageContainer: {
    width: 200,
    height: 200,
    borderRadius: 8,
    overflow: 'hidden',
    backgroundColor: colors.lightGrey,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
    borderWidth: 2,
    borderColor: colors.primary,
    borderStyle: 'dashed',
  },
  image: {
    width: 200,
    height: 200,
    resizeMode: 'cover',
  },
  placeholderContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  placeholderText: {
    marginTop: 8,
    fontSize: 16,
    color: colors.primary,
    fontWeight: 'bold',
  },
});

export default ImageSelector;