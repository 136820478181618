// History.js
import React, { useState, useEffect, useContext } from 'react';
import { StyleSheet, Text, View, ScrollView, RefreshControl, Pressable, Modal, TextInput, Dimensions, useWindowDimensions } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import OrderItem from './OrderItem';
import SelectedOrder from './SelectedOrder';
import colors from '../constants/colors';
import { getOrders } from '../services/api/orders';
import { AuthContext } from '../contexts/AuthContext';
import PlaceholderOrderItem from './PlaceholderOrderItem';

export default function Orders() {
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const { restaurant } = useContext(AuthContext);
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const {height, width, scale, fontScale} = useWindowDimensions();

  useEffect(() => {
    if (restaurant) {
      setIsLoading(true);
      getOrders(restaurant.id).then((orders) => {
        setOrders(orders);
        setIsLoading(false);
      });
    }
  }, [restaurant]);

  const onRefresh = React.useCallback(() => {
    setIsLoading(true);
    setOrders([]);
    getOrders(restaurant.id).then((orders) => {
      setOrders(orders);
      setIsLoading(false);
    });
  }, [restaurant]);

  // set max height of the order list to the height of the screen minus the height of the header
  const maxHeight = height - 88 - (width <= 800 ? 40 : 0); // 88 is the height of the header this should be changed to be dynamic

  const filteredOrders = orders && Array.isArray(orders) ? orders.filter(order => {
    return order.user && order.user.full_name && order.user.full_name.toLowerCase().includes(searchTerm.toLowerCase()) 
      || order.itemName && order.itemName.toLowerCase().includes(searchTerm.toLowerCase())
      || order.orderNumber && order.orderNumber.toString().includes(searchTerm);
  }) : [];

  const handleOrderSelect = (order) => {
    setSelectedOrder(order);
    if (width <= 800) {
      setModalVisible(true);
    }
  };

  return (
    <View style={styles.content}>
      <ScrollView style={[styles.orderList, {maxHeight: maxHeight }]}  refreshControl={
        <RefreshControl
          refreshing={isLoading}
          onRefresh={onRefresh}
        />}
      >
        {/* header  */}
        <View style={styles.header}>
          <Text style={styles.headerText}>History</Text>
          <Icon style={styles.refreshIcon} name="refresh" size={24} onPress={onRefresh} />
        </View>

        <View style={styles.searchContainer}>
          <TextInput
            style={styles.searchInput}
            value={searchTerm}
            onChangeText={setSearchTerm}
            placeholder="Search by client name or item name"
          />
          <Icon name="search" size={20} color={colors.darkGrey} style={styles.searchIcon} />
        </View>
        {isLoading ? (
          <>
            <PlaceholderOrderItem />
            <PlaceholderOrderItem />
            <PlaceholderOrderItem />
            <PlaceholderOrderItem />
            <PlaceholderOrderItem />
          </>
        ) : (
          filteredOrders.map((order, index) => (
            <OrderItem key={index} order={order} onPress={() => handleOrderSelect(order)} isSelected={selectedOrder && selectedOrder.objectId === order.objectId} />
          ))
        )}
      </ScrollView>
      {width > 800 ? (
        <View style={styles.selectedOrder}>
          <SelectedOrder order={selectedOrder} />
        </View>
      ) : (
        <Modal
          animationType="slide"
          transparent={false}
          visible={modalVisible}
          onRequestClose={() => {
            setModalVisible(!modalVisible);
          }}
        >
          <SelectedOrder order={selectedOrder} />
          <Pressable style={styles.closeButton} onPress={() => setModalVisible(false)}>
            <Icon name="close" size={30} color={colors.darkGrey} />
          </Pressable>
        </Modal>
      )}
    </View>
  );
}

const screenHeight = Dimensions.get('window').height - 88; // 88 is the height of the header this should be changed to be dynamic

const styles = StyleSheet.create({
  content: {
    flex: 3,
    flexDirection: 'row',
  },
  orderList: {
    flex: 1,
    backgroundColor: colors.lightBackgroud,
    maxHeight: screenHeight,
  },
  selectedOrder: {
    flex: 2,
    backgroundColor: colors.shyGrey,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 16,
    alignItems: 'center',
  },
  refreshIcon: {
    padding: 8,
    marginRight: 10,
    color: colors.mediumGrey,
  },
  headerText: {
    fontSize: 25,
    fontWeight: 'bold',
    color: colors.darkGrey,
    marginHorizontal: 10,
  },
  searchContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    borderColor: colors.lightGrey,
    borderWidth: 1,
    borderRadius: 20,
    paddingLeft: 15,
    margin: 10,
  },
  searchInput: {
    flex: 1,
    height: 40,
  },
  searchIcon: {
    paddingRight: 10,
  },
  closeButton: {
    position: 'absolute',
    right: 10,
    top: 10,
    backgroundColor: colors.lightGrey,
    borderRadius: 50,
    padding: 10,
  },
});