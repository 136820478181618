import React, { useState, useRef, useEffect, useContext } from 'react';
import { StyleSheet, Text, View, ScrollView, TextInput, Pressable, Switch, Modal, Dimensions, Animated, Image } from 'react-native';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Icon from 'react-native-vector-icons/MaterialIcons';
import IconCommunity from 'react-native-vector-icons/MaterialCommunityIcons';
import colors from '../constants/colors';
import ItemDetail from './ItemDetail';
import { AuthContext } from '../contexts/AuthContext';
import { getRestaurantMenuAndCategories, addNewCategory, updateDishAvailability, updateDishVariantAvailability, sortRestaurantCategories, addMenuItem, updateMenuItem } from '../services/api/restaurant';
import PlaceholderOrderItem from './PlaceholderOrderItem';
import AddMenuItemModal from './AddMenuItemModal';

const { width, height } = Dimensions.get('window');
const MODAL_WIDTH = width * 0.4; // Adjust this value as needed

export default function Menu() {
  const { restaurant } = useContext(AuthContext);
  const [menuData, setMenuData] = useState({ categories: [], dishes_by_category: {}, all_dishes: [] });
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const slideAnim = useRef(new Animated.Value(MODAL_WIDTH)).current;
  const fadeAnim = useRef(new Animated.Value(0)).current;
  const [isNewCategoryModalVisible, setIsNewCategoryModalVisible] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState('');
  const [isAddMenuItemModalVisible, setIsAddMenuItemModalVisible] = useState(false);
  const [isSavingMenuItem, setIsSavingMenuItem] = useState(false);
  const [categories, setCategories] = useState([]);

  const fetchMenuData = async () => {
    if (restaurant) {
      setIsLoading(true);
      try {
        const data = await getRestaurantMenuAndCategories(restaurant.id);
        
        // Combine and deduplicate categories
        const combinedCategories = Array.from(new Set([
          ...data.categories,
          ...restaurant.get('categories')
        ]));
        
        setMenuData({
          ...data,
          categories: combinedCategories
        });
        
        // Set categories state for draggable list
        setCategories(combinedCategories.map((category, index) => ({
          id: `category-${index}`,
          content: category
        })));
        
        // Set the first category as default if there are categories
        if (combinedCategories.length > 0) {
          setSelectedCategory(combinedCategories[0]);
        }
      } catch (error) {
        console.error('Error fetching menu data:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (restaurant) {
      fetchMenuData();
    }
  }, [restaurant]);

  useEffect(() => {
    if (selectedItem) {
      Animated.parallel([
        Animated.timing(slideAnim, {
          toValue: 0,
          duration: 300,
          useNativeDriver: false,
        }),
        Animated.timing(fadeAnim, {
          toValue: 1,
          duration: 300,
          useNativeDriver: false,
        }),
      ]).start();
    } else {
      Animated.parallel([
        Animated.timing(slideAnim, {
          toValue: MODAL_WIDTH,
          duration: 300,
          useNativeDriver: false,
        }),
        Animated.timing(fadeAnim, {
          toValue: 0,
          duration: 300,
          useNativeDriver: false,
        }),
      ]).start();
    }
  }, [selectedItem]);

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    const newCategories = Array.from(categories);
    const [reorderedItem] = newCategories.splice(result.source.index, 1);
    newCategories.splice(result.destination.index, 0, reorderedItem);

    setCategories(newCategories);

    try {
      await sortRestaurantCategories(restaurant.id, newCategories.map(category => category.content));
    } catch (error) {
      console.error('Error updating category order:', error);
    }
  };

  const renderDraggableCategory = (category, index) => (
    <Draggable key={category.id} draggableId={category.id} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            ...provided.draggableProps.style,
            opacity: snapshot.isDragging ? 0.5 : 1,
          }}
        >
          <Pressable
            style={[
              styles.categoryItem,
              {
                marginBottom: 30,
                backgroundColor: snapshot.isDragging ? colors.lightGrey : 'transparent',
              }
            ]}
            onPress={() => setSelectedCategory(category.content)}
          >
            <IconCommunity name="drag" size={24} color={colors.mediumGrey} />
            <View style={styles.categoryInfo}>
              <Text style={[styles.categoryName]}>{category.content}</Text>
              <Text style={styles.itemCount}>
                {menuData.dishes_by_category[category.content] ? menuData.dishes_by_category[category.content].length : 0} Items
              </Text>
            </View>
            <Icon name="more-vert" size={24} color={colors.mediumGrey} />
            <Icon name="chevron-right" size={24} color={selectedCategory === category.content ? colors.primary : colors.mediumGrey} />
          </Pressable>
        </div>
      )}
    </Draggable>
  );

  const handleCategorySwitch = (category, value) => {
    const updatedDishes = menuData.dishes_by_category[category].map(dish => ({
      ...dish,
      available: value
    }));

    setMenuData(prevData => ({
      ...prevData,
      dishes_by_category: {
        ...prevData.dishes_by_category,
        [category]: updatedDishes
      },
      all_dishes: prevData.all_dishes.map(dish =>
        dish.category === category ? { ...dish, available: value } : dish
      )
    }));
  };

  const handleDishAvailabilityChange = async (item, value) => {
    try {
      // Update local state for the dish
      const updatedItems = menuData.all_dishes.map(i =>
        i.objectId === item.objectId ? { ...i, available: value } : i
      );

      // Update local state for the dish variants
      const updatedVariants = item.variants.map(variant => ({
        ...variant,
        available: value
      }));

      setMenuData(prevData => ({
        ...prevData,
        all_dishes: updatedItems,
        dishes_by_category: Object.fromEntries(
          Object.entries(prevData.dishes_by_category).map(([category, dishes]) => [
            category,
            dishes.map(dish => 
              dish.objectId === item.objectId 
                ? { ...dish, available: value, variants: updatedVariants } 
                : dish
            )
          ])
        )
      }));

      // Update Parse object for the dish
      await updateDishAvailability(item.objectId, value);

      // Update Parse objects for all variants of the dish
      await Promise.all(item.variants.map(variant => 
        updateDishVariantAvailability(variant.objectId, value)
      ));
    } catch (error) {
      console.error('Error updating dish and variant availability:', error);
      alert('Failed to update dish availability. Please try again.');
    }
  };

  const renderMenuItem = (item) => {
    // Add a check for undefined item
    if (!item) return null;

    const parentVariantsCount = item.variants.filter(variant => !variant.parent).length;
    const childVariantsCount = item.variants.filter(variant => variant.parent).length;

    return (
      <View style={styles.menuItem} key={item.objectId}>
        <Pressable
          onPress={() => setSelectedItem(item)}
          style={styles.menuItemContent}
        >
          {item.image && (
            <Image source={{ uri: item.image.url }} style={styles.menuItemImage} resizeMode="cover" />
          )}
          <View style={styles.menuItemDescriptionPrincipal}>
            <View style={styles.menuItemHeader}>
              <Text style={styles.menuItemName}>{item.name}</Text>
              <Switch
                value={item.available}
                onValueChange={(value) => handleDishAvailabilityChange(item, value)}
                trackColor={{ false: colors.lightGrey, true: colors.primary }}
              />
            </View>
            <Text style={styles.menuItemPrice}>${item.price.toFixed(2)}</Text>
            <Text style={styles.menuItemDescription}>{item.description}</Text>
            <View style={styles.variantsBadgesContainer}>
              {parentVariantsCount > 0 && (
                <View style={styles.variantsContainer}>
                  <Text style={styles.variantsText}>
                    Parent Variants: {parentVariantsCount}
                  </Text>
                </View>
              )}
              {childVariantsCount > 0 && (
                <View style={styles.variantsContainer}>
                  <Text style={styles.variantsText}>
                    Child Variants: {childVariantsCount}
                  </Text>
                </View>
              )}
            </View>
          </View>
        </Pressable>
      </View>
    );
  };

  const handleAddNewCategory = async () => {
    if (newCategoryName.trim() === '') {
      alert('Por favor, ingrese un nombre de categoría válido.');
      return;
    }

    try {
      await addNewCategory(restaurant.id, newCategoryName);
      setNewCategoryName('');
      setIsNewCategoryModalVisible(false);
      fetchMenuData(); // Actualiza los datos del menú después de agregar la nueva categoría
    } catch (error) {
      console.error('Error al agregar nueva categoría:', error);
      alert('Hubo un error al agregar la nueva categoría. Por favor, inténtelo de nuevo.');
    }
  };

  // Add this function inside the Menu component, before the return statement
  const handleDeleteItem = async (itemId) => {
    try {
      // Implement the logic to delete the item from your backend
      // For example:
      // await deleteMenuItem(itemId);

      // Update the local state to remove the deleted item
      setMenuData(prevData => ({
        ...prevData,
        all_dishes: prevData.all_dishes.filter(dish => dish.objectId !== itemId),
        dishes_by_category: Object.fromEntries(
          Object.entries(prevData.dishes_by_category).map(([category, dishes]) => [
            category,
            dishes.filter(dish => dish.objectId !== itemId)
          ])
        )
      }));

      setSelectedItem(null);
    } catch (error) {
      console.error('Error deleting menu item:', error);
      alert('Failed to delete menu item. Please try again.');
    }
  };

  const handleAddOrUpdateMenuItem = async (newItem, isUpdate = false) => {
    try {
      setIsSavingMenuItem(true);
      if (!newItem.name || !newItem.price || !newItem.category) {
        alert('Por favor, complete todos los campos obligatorios (nombre, precio y categoría).');
        return;
      }

      const itemData = {
        name: newItem.name,
        price: parseFloat(newItem.price),
        description: newItem.description || '',
        category: newItem.category,
        subcategory: newItem.subcategory || '',
        available: newItem.available,
        variants: newItem.variants,
        image: newItem.image
      };

      let updatedItem;
      if (isUpdate) {
        updatedItem = await updateMenuItem(restaurant.id, newItem.objectId, itemData);
      } else {
        updatedItem = await addMenuItem(restaurant.id, itemData);
      }

      setMenuData(prevData => {
        const updatedAllDishes = isUpdate
          ? prevData.all_dishes.map(dish => dish.objectId === updatedItem.objectId ? updatedItem : dish)
          : [...prevData.all_dishes, updatedItem];
        
        const updatedDishesByCategory = {...prevData.dishes_by_category};
        
        if (isUpdate) {
          // Encontrar la categoría anterior del plato
          let oldCategory = null;
          Object.keys(updatedDishesByCategory).forEach(category => {
            const index = updatedDishesByCategory[category].findIndex(dish => dish.objectId === updatedItem.objectId);
            if (index !== -1) {
              oldCategory = category;
              updatedDishesByCategory[category].splice(index, 1);
            }
          });

          // Si la categoría ha cambiado, eliminar de la antigua y agregar a la nueva
          if (oldCategory !== updatedItem.category) {
            if (!updatedDishesByCategory[updatedItem.category]) {
              updatedDishesByCategory[updatedItem.category] = [];
            }
            updatedDishesByCategory[updatedItem.category].push(updatedItem);
          } else {
            // Si la categoría no ha cambiado, actualizar el plato en la misma categoría
            updatedDishesByCategory[updatedItem.category] = updatedDishesByCategory[updatedItem.category].map(dish =>
              dish.objectId === updatedItem.objectId ? updatedItem : dish
            );
          }
        } else {
          // Si es un nuevo plato, simplemente agregarlo a la categoría correspondiente
          if (!updatedDishesByCategory[updatedItem.category]) {
            updatedDishesByCategory[updatedItem.category] = [];
          }
          updatedDishesByCategory[updatedItem.category].push(updatedItem);
        }

        return {
          ...prevData,
          all_dishes: updatedAllDishes,
          dishes_by_category: updatedDishesByCategory
        };
      });

      setIsAddMenuItemModalVisible(false);
      setSelectedItem(null); // Cerrar el modal de detalle después de actualizar
      alert(isUpdate ? 'Ítem del menú actualizado con éxito.' : 'Nuevo ítem del menú agregado con éxito.');
    } catch (error) {
      console.error('Error al agregar/actualizar ítem del menú:', error);
      alert('Hubo un error al agregar/actualizar el ítem del menú. Por favor, inténtelo de nuevo.');
    } finally {
      setIsSavingMenuItem(false);
    }
  };

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Restaurant Menu</Text>
      <View style={styles.content}>
        <View style={styles.categoriesSection}>
          <View style={styles.sectionHeader}>
            <Text style={styles.sectionTitle}>Categories</Text>
            <Pressable style={styles.addButton} onPress={() => setIsNewCategoryModalVisible(true)}>
              <Text style={styles.addButtonText}>+ Add New Category</Text>
            </Pressable>
          </View>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="categories">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {categories.map((category, index) => renderDraggableCategory(category, index))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </View>
        <View style={styles.menuItemsSection}>
          <View style={styles.sectionHeader}>
            <Text style={styles.sectionTitle}>
              Menu Items for {selectedCategory || 'All Categories'}
            </Text>
            <Pressable style={styles.addButton} onPress={() => setIsAddMenuItemModalVisible(true)}>
              <Text style={styles.addButtonText}>+ Add New Item</Text>
            </Pressable>
          </View>
          <TextInput
            style={styles.searchInput}
            placeholder="Search"
            value={searchTerm}
            onChangeText={setSearchTerm}
            autoComplete="off"
            inputMode="text"
          />
          <ScrollView>
            {isLoading ? (
              <>
                <PlaceholderOrderItem />
                <PlaceholderOrderItem />
                <PlaceholderOrderItem />
                <PlaceholderOrderItem />
                <PlaceholderOrderItem />
              </>
            ) : selectedCategory && (!menuData.dishes_by_category[selectedCategory] || menuData.dishes_by_category[selectedCategory].length === 0) ? (
              <View style={styles.emptyCategory}>
                <Text style={styles.emptyCategoryText}>This category has no items.</Text>
                <Text style={styles.emptyCategorySubtext}>Click the "Add New" button to add an item to this category.</Text>
              </View>
            ) : (
              (selectedCategory ? menuData.dishes_by_category[selectedCategory] : menuData.all_dishes)
                .filter(item => item && item.name.toLowerCase().includes(searchTerm.toLowerCase()))
                .map(renderMenuItem)
            )}
          </ScrollView>
        </View>
      </View>
      <Modal
        visible={selectedItem !== null}
        transparent={true}
        animationType="none"
      >
        <Animated.View
          style={[
            styles.modalOverlay,
            {
              opacity: fadeAnim,
            },
          ]}
        >
          <Animated.View
            style={[
              styles.modalContent,
              {
                transform: [{ translateX: slideAnim }],
              },
            ]}
          >
            {selectedItem && (
              <ItemDetail
                item={selectedItem}
                onClose={() => setSelectedItem(null)}
                onUpdate={(updatedItem) => handleAddOrUpdateMenuItem(updatedItem, true)}
                onDelete={handleDeleteItem}
              />
            )}
          </Animated.View>
        </Animated.View>
      </Modal>

      {/* Modal para agregar nueva categoría */}
      <Modal
        visible={isNewCategoryModalVisible}
        transparent={true}
        animationType="fade"
        onRequestClose={() => setIsNewCategoryModalVisible(false)}
      >
        <View style={styles.newCategoryModalOverlay}>
          <View style={styles.newCategoryModalContent}>
            <Text style={styles.newCategoryModalTitle}>Agregar Nueva Categoría</Text>
            <TextInput
              style={styles.input}
              value={newCategoryName}
              onChangeText={setNewCategoryName}
              placeholder="Nombre de la categoría"
              autoComplete="off"
              inputMode="text"
            />
            <View style={styles.newCategoryModalButtons}>
              <Pressable
                style={[styles.newCategoryModalButton, styles.cancelButton]}
                onPress={() => setIsNewCategoryModalVisible(false)}
              >
                <Text style={styles.newCategoryModalButtonText}>Cancelar</Text>
              </Pressable>
              <Pressable
                style={[styles.newCategoryModalButton, styles.saveButton]}
                onPress={handleAddNewCategory}
              >
                <Text style={styles.newCategoryModalButtonText}>Guardar</Text>
              </Pressable>
            </View>
          </View>
        </View>
      </Modal>
      <AddMenuItemModal
        isVisible={isAddMenuItemModalVisible}
        onClose={() => setIsAddMenuItemModalVisible(false)}
        onSave={handleAddOrUpdateMenuItem}
        categories={menuData.categories}
        isSaving={isSavingMenuItem}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  flex: {
    display: 'flex'
  },
  container: {
    flex: 1,
    padding: 16,
    backgroundColor: colors.lightBackground,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 16,
  },
  content: {
    flexDirection: 'row',
    flex: 1,
  },
  categoriesSection: {
    flex: 1,
    marginRight: 16,
    backgroundColor: 'white',
    padding: 16,
    borderRadius: 10,
    boxShadow: '0px 2px 3.84px rgba(0, 0, 0, 0.25)',
  },
  menuItemsSection: {
    flex: 2,
    padding: 16,
  },
  sectionHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 16,
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  addButton: {
    backgroundColor: colors.primary,
    paddingVertical: 8,
    paddingHorizontal: 16,
    borderRadius: 4,
  },
  addButtonText: {
    color: colors.white,
    fontWeight: 'bold',
  },
  searchInput: {
    borderWidth: 1,
    borderColor: colors.lightGrey,
    borderRadius: 4,
    padding: 8,
    marginBottom: 16,
  },
  categoryItem: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 16,
    padding: 10,
    borderRadius: 8,
  },
  categoryInfo: {
    flex: 1,
    marginLeft: 8,
  },
  categoryName: {
    fontWeight: 'bold',
  },
  itemCount: {
    color: colors.mediumGrey,
  },
  menuItem: {
    marginBottom: 16,
  },
  menuItemContent: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    backgroundColor: 'white',
    borderRadius: 8,
    padding: 16,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.23,
    shadowRadius: 2.62,
    elevation: 4,
  },
  menuItemImage: {
    width: 80,
    height: 80,
    borderRadius: 8,
    marginRight: 16,
  },
  menuItemDescriptionPrincipal: {
    flex: 1,
  },
  menuItemHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 8,
  },
  menuItemName: {
    fontSize: 18,
    fontWeight: 'bold',
    flex: 1,
    marginRight: 8,
  },
  menuItemPrice: {
    fontSize: 16,
    fontWeight: 'bold',
    color: colors.primary,
    marginBottom: 4,
  },
  menuItemDescription: {
    fontSize: 14,
    color: colors.mediumGrey,
    marginBottom: 8,
  },
  variantsBadgesContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  variantsContainer: {
    backgroundColor: colors.primaryLight,
    borderRadius: 4,
    paddingHorizontal: 8,
    paddingVertical: 4,
    marginRight: 8,
    marginBottom: 4,
  },
  variantsText: {
    color: colors.primary,
    fontSize: 12,
    fontWeight: 'bold',
  },
  modalOverlay: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    width: MODAL_WIDTH,
    height: '100%',
    backgroundColor: colors.white,
  },
  selectedCategoryItem: {
    backgroundColor: colors.primaryLight,
  },
  newCategoryModalOverlay: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  newCategoryModalContent: {
    backgroundColor: colors.white,
    borderRadius: 8,
    padding: 20,
    width: '80%',
    maxWidth: 400,
  },
  newCategoryModalTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 16,
  },
  input: {
    borderWidth: 1,
    borderColor: colors.lightGrey,
    borderRadius: 4,
    padding: 8,
    marginBottom: 16,
  },
  newCategoryModalButtons: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  newCategoryModalButton: {
    paddingVertical: 8,
    paddingHorizontal: 16,
    borderRadius: 4,
    marginLeft: 8,
  },
  cancelButton: {
    backgroundColor: colors.lightGrey,
  },
  saveButton: {
    backgroundColor: colors.primary,
  },
  newCategoryModalButtonText: {
    color: colors.white,
    fontWeight: 'bold',
  },
  emptyCategory: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  emptyCategoryText: {
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 10,
  },
  emptyCategorySubtext: {
    fontSize: 14,
    color: colors.mediumGrey,
    textAlign: 'center',
  },
});