import React, { useState, useEffect } from 'react';
import { View, Text, TextInput, ScrollView, StyleSheet, Switch, Pressable, ActivityIndicator, Alert } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import colors from '../constants/colors';
import * as ImagePicker from 'expo-image-picker';
import ImageSelector from './ImageSelector';

export default function ItemDetail({ item, onClose, onUpdate, onDelete }) {
  const [editedItem, setEditedItem] = useState(null);
  const [image, setImage] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (item && !item.deleted) {
      setEditedItem({
        ...item,
        variants: item.variants || []
      });
    } else {
      onClose();
    }
  }, [item]);

  const addVariant = () => {
    setEditedItem(prevItem => ({
      ...prevItem,
      variants: [
        ...prevItem.variants,
        {
          objectId: `temp_${Date.now()}`,
          name: '',
          price: 0,
          available: true,
        }
      ]
    }));
  };

  const updateVariant = (index, field, value) => {
    setEditedItem(prevItem => ({
      ...prevItem,
      variants: prevItem.variants.map((variant, i) => 
        i === index ? { ...variant, [field]: value } : variant
      )
    }));
  };

  const removeVariant = (index) => {
    setEditedItem(prevItem => ({
      ...prevItem,
      variants: prevItem.variants.filter((_, i) => i !== index)
    }));
  };

  const handleUpdate = async () => {
    try {
      setIsSaving(true);
      const updatedItem = {
        ...editedItem,
        image: image
      };
      await onUpdate(updatedItem);
      setIsSaving(false);
      onClose();
    } catch (error) {
      console.error('Error updating item:', error);
      alert('Error updating item. Please try again.');
      setIsSaving(false);
    }
  };

  const pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
      base64: true,
    });

    if (!result.canceled) {
      const asset = result.assets[0];

      // Determinar la extensión basada en el tipo de datos en base64
      let extension = 'jpg'; // Por defecto a jpg
      if (asset.base64.startsWith('iVBORw0KGgo')) {
        extension = 'png';
      } else if (asset.base64.startsWith('R0lGODlh')) {
        extension = 'gif';
      }

      // Generar un nombre de archivo único
      const fileName = `image_${Date.now()}`;
      const fullFileName = `${fileName}.${extension}`;

      setImage({
        base64: asset.base64,
        fileName: fileName,
        extension: extension,
        fullFileName: fullFileName,
      });
    }
  };

  const handleDelete = () => {
    console.log('handleDelete function called');
    if (window.confirm("¿Estás seguro de que quieres eliminar este elemento del menú?")) {
      console.log("Eliminación confirmada");
      setIsSaving(true);
      onDelete(editedItem.objectId)
        .then(() => {
          console.log('Item deleted successfully');
          setIsSaving(false);
          onClose();
        })
        .catch((error) => {
          console.error('Error deleting item:', error);
          alert('Error deleting item. Please try again.');
          setIsSaving(false);
        });
    } else {
      console.log("Eliminación cancelada");
    }
  };

  if (!editedItem) {
    return null;
  }

  return (
    <View style={styles.modalContainer}>
      <ScrollView contentContainerStyle={styles.scrollViewContent}>
        <View style={styles.container}>
          <View style={styles.header}>
            <Text style={styles.title}>Edit Item</Text>
            <Pressable onPress={onClose} style={styles.closeButton}>
              <Icon name="close" size={24} color={colors.darkGrey} />
            </Pressable>
          </View>
          <ImageSelector
            image={image}
            onPress={pickImage}
            existingImageUrl={editedItem.image ? editedItem.image.url : null}
          />
          <View style={styles.field}>
            <Text style={styles.label}>Name</Text>
            <TextInput
              style={styles.input}
              value={editedItem.name}
              onChangeText={(text) => setEditedItem({ ...editedItem, name: text })}
            />
          </View>
          <View style={styles.field}>
            <Text style={styles.label}>Description</Text>
            <TextInput
              style={[styles.input, styles.multilineInput]}
              value={editedItem.description}
              onChangeText={(text) => setEditedItem({ ...editedItem, description: text })}
              multiline
            />
          </View>
          <View style={styles.field}>
            <Text style={styles.label}>Category</Text>
            <TextInput
              style={styles.input}
              value={editedItem.category}
              onChangeText={(text) => setEditedItem({ ...editedItem, category: text })}
            />
          </View>
          <View style={styles.field}>
            <Text style={styles.label}>Price</Text>
            <TextInput
              style={styles.input}
              value={editedItem.price.toString()}
              onChangeText={(text) => setEditedItem({ ...editedItem, price: parseFloat(text) || 0 })}
              keyboardType="numeric"
            />
          </View>
          <View style={styles.field}>
            <Text style={styles.label}>Availability</Text>
            <Switch
              value={editedItem.available}
              onValueChange={(value) => setEditedItem({ ...editedItem, available: value })}
              trackColor={{ false: colors.lightGrey, true: colors.primary }}
            />
          </View>
          <View style={styles.field}>
            <Text style={styles.label}>Variants</Text>
            {editedItem.variants.map((variant, index) => (
              <View key={variant.objectId || index} style={styles.variantContainer}>
                <TextInput
                  style={styles.variantInput}
                  value={variant.name}
                  onChangeText={(text) => updateVariant(index, 'name', text)}
                  placeholder="Variant name"
                />
                <TextInput
                  style={styles.variantInput}
                  value={variant.price ? variant.price.toString() : ''}
                  onChangeText={(text) => updateVariant(index, 'price', parseFloat(text) || 0)}
                  keyboardType="numeric"
                  placeholder="Price"
                />
                <Switch
                  value={variant.available}
                  onValueChange={(value) => updateVariant(index, 'available', value)}
                  trackColor={{ false: colors.lightGrey, true: colors.primary }}
                />
                <Pressable onPress={() => removeVariant(index)}>
                  <Icon name="delete" size={24} color={colors.danger} />
                </Pressable>
              </View>
            ))}
            <Pressable style={styles.addButton} onPress={addVariant}>
              <Text style={styles.addButtonText}>Add Variant</Text>
            </Pressable>
          </View>
        </View>
      </ScrollView>
      <View style={styles.footer}>
        <View style={styles.buttonContainer}>
          <Pressable 
            style={styles.deleteButton} 
            onPress={handleDelete}
            testID="deleteButton"
          >
            <Text style={styles.deleteButtonText}>Eliminar</Text>
          </Pressable>
          <View style={styles.rightButtons}>
            <Pressable style={styles.cancelButton} onPress={onClose}>
              <Text style={styles.cancelButtonText}>Cancelar</Text>
            </Pressable>
            <Pressable 
              style={[styles.saveButton, isSaving && styles.disabledButton]} 
              onPress={handleUpdate}
              disabled={isSaving}
            >
              {isSaving ? (
                <ActivityIndicator color={colors.white} />
              ) : (
                <Text style={styles.saveButtonText}>Guardar</Text>
              )}
            </Pressable>
          </View>
        </View>
      </View>
      {isSaving && (
        <View style={styles.overlay}>
          <ActivityIndicator size="large" color={colors.primary} />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    backgroundColor: colors.white,
  },
  scrollViewContent: {
    flexGrow: 1,
  },
  container: {
    flex: 1,
    padding: 16,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 16,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
  },
  closeButton: {
    padding: 8,
  },
  field: {
    marginBottom: 16,
  },
  label: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 8,
  },
  input: {
    borderWidth: 1,
    borderColor: colors.lightGrey,
    borderRadius: 4,
    padding: 8,
  },
  multilineInput: {
    height: 100,
    textAlignVertical: 'top',
  },
  variantContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
  },
  variantInput: {
    flex: 1,
    borderWidth: 1,
    borderColor: colors.lightGrey,
    borderRadius: 4,
    padding: 8,
    marginRight: 8,
  },
  footer: {
    padding: 16,
    borderTopWidth: 1,
    borderTopColor: colors.lightGrey,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  rightButtons: {
    flexDirection: 'row',
  },
  cancelButton: {
    backgroundColor: colors.lightGrey,
    paddingVertical: 8,
    paddingHorizontal: 16,
    borderRadius: 4,
    marginRight: 8,
  },
  cancelButtonText: {
    color: colors.darkGrey,
    fontWeight: 'bold',
  },
  saveButton: {
    backgroundColor: colors.primary,
    paddingVertical: 8,
    paddingHorizontal: 16,
    borderRadius: 4,
  },
  saveButtonText: {
    color: colors.white,
    fontWeight: 'bold',
  },
  disabledButton: {
    opacity: 0.5,
  },
  addButton: {
    backgroundColor: colors.primary,
    padding: 12,
    borderRadius: 4,
    alignItems: 'center',
    marginTop: 16,
  },
  addButtonText: {
    color: colors.white,
    fontWeight: 'bold',
  },
  overlay: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  deleteButton: {
    backgroundColor: '#FF3B30',
    paddingVertical: 8,
    paddingHorizontal: 16,
    borderRadius: 4,
  },
  deleteButtonText: {
    color: colors.white,
    fontWeight: 'bold',
  },
});