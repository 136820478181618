import React, { useState, useEffect, useContext } from 'react';
import { View, Text, StyleSheet, ActivityIndicator } from 'react-native';
import { CircularProgressBase } from 'react-native-circular-progress-indicator';
import { Icon } from 'react-native-elements';
import colors from '../../constants/colors';
import { getOrders } from '../../services/api/orders';
import { AuthContext } from '../../contexts/AuthContext';

export default function AveragePreparationTime({ startDate, endDate }) {
  const [averagePrepTime, setAveragePrepTime] = useState(0);
  const [previousAveragePrepTime, setPreviousAveragePrepTime] = useState(0);
  const [changePercentage, setChangePercentage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingPrevious, setIsLoadingPrevious] = useState(true);
  const { restaurant } = useContext(AuthContext);

  useEffect(() => {
    if (restaurant && startDate && endDate) {
      fetchPreparationTimeData();
    }
  }, [restaurant, startDate, endDate]);

  const fetchPreparationTimeData = async () => {
    try {
      setIsLoading(true);
      // console.log('Fetching orders for date range:', startDate, endDate);
      const orders = await getOrders(restaurant.id, startDate.toISOString(), endDate.toISOString(), [
        'objectId',
        'startedCookingAt',
        'finishedCookingAt',
        'duration',
        'createdAt'
      ],  1000, ['DELIVERED']);

      const avgPrepTime = calculateAveragePreparationTime(orders);
      
      setAveragePrepTime(avgPrepTime);
      
      setIsLoading(false);

      // After setting the primary data, fetch the previous period data
      fetchPreviousPeriodData();
    } catch (error) {
      console.error('Error fetching preparation time data:', error);
      setIsLoading(false);
    }
  };

  const fetchPreviousPeriodData = async () => {
    try {
      setIsLoadingPrevious(true);
      const periodLength = endDate.getTime() - startDate.getTime();
      const previousStartDate = new Date(startDate.getTime() - periodLength);
      const previousEndDate = new Date(endDate.getTime() - periodLength);

      const previousOrders = await getOrders(restaurant.id, previousStartDate.toISOString(), previousEndDate.toISOString(), [
        'objectId',
        'startedCookingAt',
        'finishedCookingAt',
        'createdAt',
        'duration'
      ],  1000, ['DELIVERED']);
      
      const prevAvgPrepTime = calculateAveragePreparationTime(previousOrders);

      setPreviousAveragePrepTime(prevAvgPrepTime);

      // Calculate change percentage
      if (prevAvgPrepTime > 0) {
        const changePercentage = ((averagePrepTime - prevAvgPrepTime) / prevAvgPrepTime * 100).toFixed(1);
        setChangePercentage(changePercentage);
      } else {
        setChangePercentage('N/A');
      }

      setIsLoadingPrevious(false);
    } catch (error) {
      console.error('Error fetching previous period preparation time data:', error);
      setIsLoadingPrevious(false);
    }
  };

  const calculatePreparationTime = (order) => {
    let startTime, endTime, duration;

    // Determinar el tiempo de inicio
    if (order.startedCookingAt?.iso) {
      startTime = new Date(order.startedCookingAt.iso);
    } else if (order.createdAt?.iso) {
      startTime = new Date(order.createdAt.iso);
    }

    // Determinar el tiempo de finalización
    if (order.finishedCookingAt?.iso) {
      endTime = new Date(order.finishedCookingAt.iso);
    }

    // Calcular la duración
    if (startTime && endTime) {
      duration = (endTime - startTime) / (1000 * 60); // Convertir a milisegundos a minutos
      // Se divide entre 1000 para convertir milisegundos a segundos, y luego entre 60 para convertir segundos a minutos
    }

    return duration && duration > 0 ? duration : null;
  };

  const calculateAveragePreparationTime = (orders) => {
    const validPrepTimes = orders
      .map(order => calculatePreparationTime(order))
      .filter(time => time !== null);

    if (validPrepTimes.length === 0) {
      console.warn('No valid preparation times found in orders');

      return 0;
    }

    const avgPrepTime = validPrepTimes.reduce((sum, time) => sum + time, 0) / validPrepTimes.length;

    return Math.round(avgPrepTime);
  };

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Average Preparation Time</Text>
      <View style={styles.content}>
        <View style={styles.timeContainer}>
          {isLoading ? (
            <ActivityIndicator size="small" color={colors.primary} />
          ) : (
            <>
              <Text style={styles.time}>
                {averagePrepTime > 0 ? `${averagePrepTime} min` : 'No data'}
              </Text>
              {!isLoadingPrevious && changePercentage !== 'N/A' && (
                <Text style={[styles.change, { color: changePercentage <= 0 ? colors.success : colors.bad }]}>
                  {changePercentage >= 0 ? '+' : ''}{changePercentage}% {changePercentage <= 0 ? '↓' : '↑'}
                </Text>
              )}
            </>
          )}
        </View>
        <CircularProgressBase
          value={averagePrepTime}
          radius={75}
          activeStrokeWidth={5}
          inActiveStrokeWidth={20}
          progressValueColor={colors.primary}
          activeStrokeColor={colors.primary}
          inActiveStrokeColor={colors.lightGrey}
          inActiveStrokeOpacity={0.5}
          duration={1000}
        >
          <Icon
            name='stopwatch'
            type='font-awesome-5'
            color='#000'
            size={50}
          />
        </CircularProgressBase>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    borderRadius: 10,
    padding: 20,
    marginBottom: 20,
    shadowColor: colors.darkGrey,
    shadowOffset: { width: 5, height: 5 },
    shadowOpacity: 0.1,
    shadowRadius: 5,
    elevation: 5,
  },
  title: {
    fontSize: 16,
    fontWeight: '400',
    color: colors.darkGrey,
    marginBottom: 10,
  },
  content: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  timeContainer: {
    alignItems: 'flex-start',
  },
  time: {
    fontSize: 30,
    fontWeight: 'bold',
    color: colors.darkGrey,
    lineHeight: 43,
  },
  change: {
    color: colors.success,
  },
});