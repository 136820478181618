import { Platform } from 'react-native';

export const fonts = {
  regular: Platform.OS === 'ios' ? 'System' : 'Roboto',
  medium: Platform.OS === 'ios' ? 'System' : 'Roboto-Medium',
  bold: Platform.OS === 'ios' ? 'System' : 'Roboto-Bold',
};

export const fontSizes = {
  small: 12,
  medium: 16,
  large: 20,
  extraLarge: 24,
};
