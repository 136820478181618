import React, { useState, useEffect, useContext } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import colors from '../../constants/colors';
import { ActivityIndicator } from 'react-native';
import { Tooltip } from 'react-native-elements';
import { AntDesign } from '@expo/vector-icons';

export default function MetricCard({ title, value, change, isLoading, hasBorder, onInfoPress }) {
  const [open, setOpen] = useState(false);

  return (
    <View style={[styles.card, hasBorder && styles.borderRight]}>
      <View style={styles.cardTitleContainer}>
      <Text style={styles.cardTitle}>{title}</Text>
      {title === 'Total Taxes' && (
        <Tooltip
          visible={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          width={400}
          height={100}
          backgroundColor={'#f2efef'}
          borderColor={colors.darkGrey}
          borderRadius={5}
          popoverStyle={{ borderRadius: 5 }}
          popover={<Text style={{ color: "#000" }}>Uva is recognized as a retenedor de mercado in Puerto Rico, meaning we handle the deposit of state and municipal taxes on your behalf. As a result, these taxes won't appear in your weekly deposit. </Text>}
        >
          <AntDesign name="infocirlce" size={18} color="black" />
        </Tooltip>
      )}
    </View>
    {
      isLoading ? (
        <ActivityIndicator size="small" color={colors.primary} />
      ) : (
        <>
          <Text style={styles.cardValue}>{value}</Text>
          {change !== 'N/A' && (
            <Text style={[styles.cardChange, { color: parseFloat(change) >= 0 ? colors.success : colors.bad }]}>
              {parseFloat(change) >= 0 ? '+' : ''}{change}% {parseFloat(change) >= 0 ? '↑' : '↓'}
            </Text>
          )}
        </>
      )
    }
    </View>
  );
}

const styles = StyleSheet.create({
  card: {
    backgroundColor: colors.white,
    padding: 5,
    flex: 1,
    marginHorizontal: 5,
  },
  cardTitle: {
    fontSize: 14,
    color: '#122632',
    marginBottom: 5,
    marginRight: 5,
  },
  cardValue: {
    fontSize: 30,
    fontWeight: 'bold',
    color: colors.darkGrey,
    marginBottom: 5,
  },
  cardChange: {
    fontSize: 14,
    fontWeight: 700,
  },
  borderRight: {
    borderRightWidth: 1,
    borderRightColor: 'rgba(0, 0, 0, 0.2)',
  },
  cardTitleContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  }
}); 