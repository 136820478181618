import React, { useState, useEffect, useContext } from 'react';
import { View, Text, StyleSheet, ActivityIndicator } from 'react-native';
import { BarChart } from 'react-native-svg-charts'
import colors from '../../constants/colors';
import { getOrders } from '../../services/api/orders';
import { AuthContext } from '../../contexts/AuthContext';
import moment from 'moment';

export default function OrdersChart({ startDate, endDate, title, type }) {
  const [orderData, setOrderData] = useState({
    labels: [],
    datasets: [{ data: [] }],
  });
  const [totalOrders, setTotalOrders] = useState(0);
  // const [canceledPercentage, setCanceledPercentage] = useState(0);
  // const [previousTotalOrders, setPreviousTotalOrders] = useState(0);
  const [changePercentage, setChangePercentage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingPrevious, setIsLoadingPrevious] = useState(true);
  const { restaurant } = useContext(AuthContext);

  const fill = 'rgb(244, 81, 29)'
  const [data, setData] = useState([]);
  // [50, 10, 40, 95, 4, 24, 10, 85, 20, 10, 35, 53, 53, 24, 50, 20, 80]

  useEffect(() => {
    if (restaurant && startDate && endDate) {
      fetchOrderData();
    }
  }, [startDate, endDate, restaurant]);

  useEffect(() => {
    if (!isLoading && totalOrders > 0) {
      if (restaurant) {
        fetchPreviousPeriodData();
      }
    }
  }, [isLoading, totalOrders]);

  const fetchOrderData = async () => {
    try {
      setIsLoading(true);

      let status = [];

      if (type === 'ordersNumber') {
        status = ['DELIVERED'];
      } else if (type === 'ordersCanceled') {
        status = ['CANCELLED', 'DELIVERED'];
      }

      let orders = await getOrders(restaurant.id, startDate.toISOString(), endDate.toISOString(), [
        'objectId',
        'createdAt',
        'status'
      ], 1000, status);

      orders = orders.map(order => {
        return {
          ...order,
          date: order.createdAt.split('T')[0], // YYYY-MM-DD
          total: order.restaurantSubtotals
        }
      })

      if (type === 'ordersCanceled') {
        const canceledOrders = orders.filter(order => order.status === 'CANCELLED');

        const percentage = (canceledOrders.length / orders.length) * 100;
        
        setTotalOrders(percentage.toFixed(2));

        processOrders(canceledOrders);
      } else {
        setTotalOrders(orders.length);

        processOrders(orders);
      }
      
      // setTotalOrders(orders.length);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching order data:', error);
      setIsLoading(false);
    }
  };

  const fetchPreviousPeriodData = async () => {
    try {
      setIsLoadingPrevious(true);
      const periodLength = endDate.getTime() - startDate.getTime();
      const previousStartDate = new Date(startDate.getTime() - periodLength);
      const previousEndDate = new Date(endDate.getTime() - periodLength);

      let status = [];

      if (type === 'ordersNumber') {
        status = ['DELIVERED'];
      } else if (type === 'ordersCanceled') {
        status = ['CANCELLED', 'DELIVERED'];
      }

      const previousOrders = await getOrders(restaurant.id, previousStartDate.toISOString(), previousEndDate.toISOString(), [
        'objectId',
        'createdAt'
      ], 1000, status);

      // Calculate change percentage
      if (previousOrders.length > 0) {

        const changePercentage = ((totalOrders - previousOrders.length) / previousOrders.length * 100).toFixed(1);

        setChangePercentage(changePercentage);
      } else if (totalOrders > 0) {
        setChangePercentage('100.0');
      } else {
        setChangePercentage('0.0');
      }

      setIsLoadingPrevious(false);
    } catch (error) {
      console.error('Error fetching previous period order data:', error);
      setIsLoadingPrevious(false);
    }
  };

  const processOrders = (orders) => {
    let period = 'day';

    const daysDiff = moment(endDate).diff(moment(startDate), 'days');

    if (daysDiff > 90) {
      period = 'month';
    } else if (daysDiff > 30 && daysDiff <= 90) {
      period = 'week';
    }

    return aggregateData(orders, period);
  };

  const aggregateData = (orders, period) => {
    const aggregated = orders.reduce((acc, order) => {
      const key = moment(order.date).format(period === 'day' ? 'YYYY-MM-DD' : (period === 'week' ? 'YYYY-W' : 'YYYY-MM'));
      if (!acc[key]) {
        acc[key] = { date: key, count: 0 };
      }
      acc[key].count += 1;
      return acc;
    }, {});

    let sortedData = Object.values(aggregated)
      .sort((a, b) => a.date.localeCompare(b.date))
      .map(item => item.count);

    if (sortedData.length === 1) {
      sortedData = [...sortedData, 0];
    }

    setData(sortedData);
  };

  return (
    <View style={styles.container}>
      <Text style={styles.title}>{title}</Text>
      <View style={styles.contentContainer}>
        <View style={styles.dataContainer}>
          {isLoading ? (
            <ActivityIndicator size="small" color={colors.primary} />
          ) : (
            <>
              <Text style={styles.orderCount}>
                {type === 'ordersCanceled' 
                  ? parseFloat(totalOrders).toFixed(1) 
                  : totalOrders}
                {type === 'ordersCanceled' ? '%' : ''}
              </Text>
              {!isLoadingPrevious && (
                <Text style={[styles.change, { color: parseFloat(changePercentage) >= 0 ? colors.success : colors.bad }]}>
                  {changePercentage >= 0 ? '+' : ''}{parseFloat(changePercentage).toFixed(1)}% {changePercentage <= 0 ? '↓' : '↑'}
                </Text>
              )}
            </>
          )}
        </View>

        <View style={styles.chartContainer}>
          <BarChart 
            style={{ height: 150 }} 
            data={data} 
            svg={{ fill }} 
            contentInset={{ top: 0, bottom: 30 }}
          >
          </BarChart>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    borderRadius: 10,
    padding: 20,
    shadowColor: colors.darkGrey,
    shadowOffset: { width: 5, height: 5 },
    shadowOpacity: 0.1,
    shadowRadius: 5,
    elevation: 5,
    marginBottom: 20,
  },
  title: {
    fontSize: 16,
    fontWeight: '400',
    color: colors.darkGrey,
    marginBottom: 10,
  },
  contentContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  dataContainer: {
    flex: 1,
    marginRight: 10,
  },
  chartContainer: {
    flex: 2,
  },
  orderCount: {
    fontSize: 24,
    fontWeight: 'bold',
    color: colors.darkGrey,
    marginRight: 10,
    lineHeight: 32,
  },
  change: {
    fontSize: 16,
    fontWeight: '400',
  },
  chart: {
    marginVertical: 8,
    borderRadius: 16,
  },
});