import React from 'react';
import { Modal, View, Text, Image, ScrollView, StyleSheet, TouchableOpacity } from 'react-native';
import colors from '../constants/colors';
import Icon from 'react-native-vector-icons/MaterialIcons';

export default function OrderDetailModal({ isVisible, onClose, order }) {
  if (!order) return null;

  const user = order.user || {};
  const driver = order.driver || {};
  const dishes = order.dishes || [];

  console.log('user', user);
  return (
    <Modal
      animationType="slide"
      transparent={false}
      visible={isVisible}
      onRequestClose={onClose}
    >
      <View style={styles.container}>
        <View style={styles.header}>
          <TouchableOpacity onPress={onClose} style={styles.backButton}>
            <Icon name="arrow-back" size={24} color={colors.white} />
          </TouchableOpacity>
          <Text style={styles.headerText}>Order Detail</Text>
          <View style={styles.placeholder} />
        </View>
        <ScrollView>
          <View style={styles.orderInfo}>
            <View style={styles.orderInfoRow}>
              <View style={styles.orderInfoColumn}>
                <Text style={styles.label}>Order</Text>
                <Text style={styles.value}>#{order.orderNumber || 'N/A'}</Text>
              </View>
              <View style={styles.orderInfoColumn}>
                <Text style={styles.label}>OrderID</Text>
                <Text style={styles.value}>{order.objectId || 'N/A'}</Text>
              </View>
              <View style={styles.orderInfoColumn}>
                <Text style={styles.label}>Order Date & Time</Text>
                <Text style={styles.value}>{order.createdAt ? new Date(order.createdAt).toLocaleString() : 'N/A'}</Text>
              </View>
            </View>
          </View>

          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Client Details</Text>
            <View style={styles.clientInfo}>
              {user?.profileImage?.url ? (
                <Image source={{ uri: user.profileImage.url }} style={styles.clientImage} />
              ) : (
                <View style={styles.clientImagePlaceholder}>
                  <Icon name="person" size={40} color={colors.mediumGrey} />
                </View>
              )}
              <View style={styles.clientDetails}>
                <Text style={styles.clientName}>{user.full_name || 'N/A'}</Text>
                <Text style={styles.clientAddress}>{user.address || 'N/A'}</Text>
                <Text style={styles.phoneNumber}>{user.phoneNumber || 'N/A'}</Text>
                <Text style={styles.itemsOrdered}>Items Ordered: {dishes.length} items</Text>
              </View>
            </View>
          </View>

          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Driver Details</Text>
            <View style={styles.driverInfo}>
              <Icon name="directions-car" size={40} color={colors.mediumGrey} style={styles.driverIcon} />
              <View style={styles.driverDetails}>
                <Text style={styles.driverName}>{driver.name || 'N/A'}</Text>
                <Text style={styles.driverSince}>Driver since {driver.since || 'N/A'}</Text>
                <Text style={styles.driverPhone}>{driver.phoneNumber || 'N/A'}</Text>
                <Text style={styles.driverEarnings}>Driver's Earnings: ${(driver.earnings || 0).toFixed(2)}</Text>
              </View>
            </View>
          </View>

          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Dish Details</Text>
            {dishes.map((dish, index) => (
              <View key={index} style={styles.dishItem}>
                <View style={styles.dishHeader}>
                  <Text style={styles.dishName}>{dish.count || 1}x {dish.name || 'N/A'}</Text>
                  <Text style={styles.dishPrice}>$ {((dish.price || 0) / 100).toFixed(2)}</Text>
                </View>
                {dish.dishVariants && dish.dishVariants.length > 0 && (
                  <View style={styles.variantsContainer}>
                    <Text style={styles.variantsTitle}>Variants</Text>
                    {dish.dishVariants.map((variant, vIndex) => (
                      <View key={vIndex} style={styles.variantItem}>
                        <Text style={styles.variantName}>{variant.name || 'N/A'}</Text>
                        <Text style={styles.variantPrice}>$ {((variant.price || 0) / 100).toFixed(2)}</Text>
                      </View>
                    ))}
                  </View>
                )}
                {dish.notes && (
                  <View style={styles.notesContainer}>
                    <Text style={styles.notesTitle}>Notes</Text>
                    <Text style={styles.dishNotes}>{dish.notes}</Text>
                  </View>
                )}
              </View>
            ))}
          </View>

          <View style={styles.totalSection}>
            <Text style={styles.totalLabel}>Total</Text>
            <Text style={styles.totalValue}>$ {(order.subtotalData.totalRestaurantSubtotal || 0).toFixed(2)}</Text>
          </View>
        </ScrollView>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 16,
    backgroundColor: colors.primary,
  },
  headerText: {
    fontSize: 20,
    fontWeight: 'bold',
    color: colors.white,
  },
  backButton: {
    padding: 8,
  },
  placeholder: {
    width: 32,
  },
  orderInfo: {
    padding: 16,
    borderBottomWidth: 1,
    borderBottomColor: colors.lightGrey,
  },
  orderInfoRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  orderInfoColumn: {
    flex: 1,
  },
  label: {
    fontSize: 14,
    color: colors.mediumGrey,
  },
  value: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 8,
  },
  section: {
    padding: 16,
    borderBottomWidth: 1,
    borderBottomColor: colors.lightGrey,
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 8,
  },
  clientInfo: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  clientImage: {
    width: 50,
    height: 50,
    borderRadius: 25,
    marginRight: 16,
  },
  clientImagePlaceholder: {
    width: 50,
    height: 50,
    borderRadius: 25,
    marginRight: 16,
    backgroundColor: colors.lightGrey,
    justifyContent: 'center',
    alignItems: 'center',
  },
  clientDetails: {
    flex: 1,
  },
  clientName: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  clientAddress: {
    fontSize: 14,
    color: colors.mediumGrey,
  },
  phoneNumber: {
    fontSize: 14,
    color: colors.mediumGrey,
  },
  itemsOrdered: {
    fontSize: 14,
    color: colors.mediumGrey,
  },
  dishItem: {
    marginBottom: 16,
  },
  dishHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dishName: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  dishPrice: {
    fontSize: 16,
    fontWeight: 'bold',
    color: colors.primary,
  },
  variantsContainer: {
    marginTop: 8,
    backgroundColor: colors.lightGrey,
    padding: 8,
    borderRadius: 4,
  },
  variantsTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 4,
  },
  variantItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginLeft: 16,
  },
  variantName: {
    fontSize: 14,
    color: colors.mediumGrey,
  },
  variantPrice: {
    fontSize: 14,
    color: colors.primary,
  },
  notesContainer: {
    marginTop: 8,
    backgroundColor: colors.lightYellow,
    padding: 8,
    borderRadius: 4,
  },
  notesTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 4,
  },
  dishNotes: {
    fontSize: 14,
    fontStyle: 'italic',
    color: colors.mediumGrey,
  },
  totalSection: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 16,
    borderTopWidth: 1,
    borderTopColor: colors.lightGrey,
  },
  totalLabel: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  totalValue: {
    fontSize: 18,
    fontWeight: 'bold',
    color: colors.primary,
  },
  driverInfo: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  driverIcon: {
    marginRight: 16,
  },
  driverDetails: {
    flex: 1,
  },
  driverName: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  driverSince: {
    fontSize: 14,
    color: colors.mediumGrey,
  },
  driverPhone: {
    fontSize: 14,
    color: colors.mediumGrey,
  },
  driverEarnings: {
    fontSize: 14,
    fontWeight: 'bold',
    color: colors.primary,
  },
});