import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, FlatList, TouchableOpacity, Modal, TextInput } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import FontAwesome from 'react-native-vector-icons/FontAwesome';

// Función para determinar el tipo de tarjeta basado en el número
const getCardType = (number) => {
  const firstDigit = number.charAt(0);
  const firstTwoDigits = number.substring(0, 2);
  
  if (number.startsWith('34') || number.startsWith('37')) return 'cc-amex';
  if (firstDigit === '4') return 'cc-visa';
  if (firstTwoDigits >= '51' && firstTwoDigits <= '55') return 'cc-mastercard';
  if (number.startsWith('6011') || number.startsWith('65')) return 'cc-discover';
  return 'credit-card';
};

// Componente para mostrar el icono de la tarjeta
const CardIcon = ({ cardType }) => {
  return <FontAwesome name={cardType} size={24} color="#000" />;
};

const PaymentMethodItem = ({ method, isPrimary, onOptionsPress }) => (
  <View style={styles.paymentMethodItem}>
    <CardIcon cardType={getCardType(method.number)} />
    <View style={styles.cardInfo}>
      <Text style={styles.cardNumber}>{method.type} .{method.lastFour}</Text>
      <Text style={styles.cardExpiry}>Expires on {method.expiryDate}</Text>
    </View>
    {isPrimary && <View style={styles.primaryBadge}><Text style={styles.primaryText}>Primary</Text></View>}
    <TouchableOpacity onPress={onOptionsPress}>
      <Icon name="more-vert" size={24} color="#000" />
    </TouchableOpacity>
  </View>
);

const PaymentActivityItem = ({ activity }) => (
  <View style={styles.activityItem}>
    <Text style={styles.activityDate}>{activity.date}</Text>
    <Text style={styles.activityMethod}>{activity.method}</Text>
    <Text style={styles.activityStatus}>{activity.status}</Text>
    <Text style={styles.activityAmount}>${activity.amount.toFixed(2)}</Text>
  </View>
);

export default function Payments() {
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [paymentActivity, setPaymentActivity] = useState([]);
  const [totalBudget, setTotalBudget] = useState(0);
  const [amountSpent, setAmountSpent] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [newCardNumber, setNewCardNumber] = useState('');
  const [newCardExpiry, setNewCardExpiry] = useState('');
  const [newCardCVV, setNewCardCVV] = useState('');
  const [newCardType, setNewCardType] = useState('credit-card');

  useEffect(() => {
    // Fetch payment methods, activity, budget, and spent amount from API
    // For now, we'll use dummy data
    setPaymentMethods([
      { id: 1, type: 'VISA', number: '4111111111111111', lastFour: '1111', expiryDate: '08/2023', isPrimary: true },
      { id: 2, type: 'Mastercard', number: '5555555555554444', lastFour: '4444', expiryDate: '01/2024', isPrimary: false },
    ]);
    setPaymentActivity([
      { id: 1, date: '06-02-2021', method: 'VISA .1256', status: 'Paid', amount: 500 },
      { id: 2, date: '06-02-2021', method: 'VISA .1256', status: 'Paid', amount: 500 },
      { id: 3, date: '06-02-2021', method: 'VISA .1256', status: 'Paid', amount: 500 },
    ]);
    setTotalBudget(2000);
    setAmountSpent(1500);
  }, []);

  const handleAddPaymentMethod = () => {
    setModalVisible(true);
  };

  const handlePaymentMethodOptions = (methodId) => {
    // Implement options menu logic (Set Primary, Delete)
    console.log('Options for payment method', methodId);
  };

  const handleSubmitNewCard = () => {
    // Aquí implementarías la lógica para añadir la nueva tarjeta
    console.log('Adding new card:', { newCardNumber, newCardExpiry, newCardCVV, newCardType });
    // Después de añadir la tarjeta, cierra el modal y limpia los campos
    setModalVisible(false);
    setNewCardNumber('');
    setNewCardExpiry('');
    setNewCardCVV('');
    setNewCardType('credit-card');
  };

  const handleCardNumberChange = (number) => {
    setNewCardNumber(number);
    setNewCardType(getCardType(number));
  };

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Payment</Text>
      
      <Text style={styles.sectionTitle}>Payment Method</Text>
      <FlatList
        data={paymentMethods}
        renderItem={({ item }) => (
          <PaymentMethodItem
            method={item}
            isPrimary={item.isPrimary}
            onOptionsPress={() => handlePaymentMethodOptions(item.id)}
          />
        )}
        keyExtractor={(item) => item.id.toString()}
      />
      <TouchableOpacity style={styles.addButton} onPress={handleAddPaymentMethod}>
        <Text style={styles.addButtonText}>Add Payment Method</Text>
      </TouchableOpacity>

      <View style={styles.budgetContainer}>
        <View style={styles.budgetItem}>
          <Text style={styles.budgetLabel}>Total Budget</Text>
          <Text style={styles.budgetAmount}>${totalBudget.toFixed(2)}</Text>
        </View>
        <View style={styles.budgetItem}>
          <Text style={styles.budgetLabel}>Amount Spent</Text>
          <Text style={styles.budgetAmount}>${amountSpent.toFixed(2)}</Text>
        </View>
      </View>

      <Text style={styles.sectionTitle}>Payment Activity</Text>
      <View style={styles.activityHeader}>
        <Text style={styles.activityHeaderText}>Date</Text>
        <Text style={styles.activityHeaderText}>Payment method</Text>
        <Text style={styles.activityHeaderText}>Status</Text>
        <Text style={styles.activityHeaderText}>Amount</Text>
      </View>
      <FlatList
        data={paymentActivity}
        renderItem={({ item }) => <PaymentActivityItem activity={item} />}
        keyExtractor={(item) => item.id.toString()}
      />

      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => setModalVisible(false)}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <Text style={styles.modalTitle}>Add New Payment Method</Text>
            <View style={styles.cardTypeContainer}>
              <CardIcon cardType={newCardType} />
              <TextInput
                style={styles.input}
                placeholder="Card Number"
                value={newCardNumber}
                onChangeText={handleCardNumberChange}
                keyboardType="numeric"
              />
            </View>
            <TextInput
              style={styles.input}
              placeholder="Expiry Date (MM/YY)"
              value={newCardExpiry}
              onChangeText={setNewCardExpiry}
            />
            <TextInput
              style={styles.input}
              placeholder="CVV"
              value={newCardCVV}
              onChangeText={setNewCardCVV}
              keyboardType="numeric"
              secureTextEntry
            />
            <TouchableOpacity style={styles.submitButton} onPress={handleSubmitNewCard}>
              <Text style={styles.submitButtonText}>Add Card</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.closeButton}
              onPress={() => setModalVisible(false)}
            >
              <Icon name="close" size={24} color="#000" />
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    backgroundColor: '#f0f0f0',
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 16,
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginTop: 16,
    marginBottom: 8,
  },
  paymentMethodItem: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#fff',
    padding: 16,
    marginBottom: 8,
    borderRadius: 8,
  },
  cardInfo: {
    flex: 1,
  },
  cardNumber: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  cardExpiry: {
    fontSize: 14,
    color: '#666',
  },
  primaryBadge: {
    backgroundColor: '#ffe4d6',
    paddingHorizontal: 8,
    paddingVertical: 4,
    borderRadius: 12,
    marginRight: 8,
  },
  primaryText: {
    color: '#ff5722',
    fontSize: 12,
  },
  addButton: {
    backgroundColor: '#ff5722',
    padding: 12,
    borderRadius: 8,
    alignItems: 'center',
    marginTop: 8,
  },
  addButtonText: {
    color: '#fff',
    fontWeight: 'bold',
  },
  budgetContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 16,
  },
  budgetItem: {
    backgroundColor: '#fff',
    padding: 16,
    borderRadius: 8,
    flex: 1,
    marginHorizontal: 4,
  },
  budgetLabel: {
    fontSize: 14,
    color: '#666',
  },
  budgetAmount: {
    fontSize: 20,
    fontWeight: 'bold',
    marginTop: 4,
  },
  activityHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 8,
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
  },
  activityHeaderText: {
    fontWeight: 'bold',
    flex: 1,
  },
  activityItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 12,
    borderBottomWidth: 1,
    borderBottomColor: '#eee',
  },
  activityDate: {
    flex: 1,
  },
  activityMethod: {
    flex: 1,
  },
  activityStatus: {
    flex: 1,
  },
  activityAmount: {
    flex: 1,
    textAlign: 'right',
    fontWeight: 'bold',
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalView: {
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: '80%',
  },
  modalTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 15,
  },
  input: {
    height: 40,
    width: '100%',
    borderColor: 'gray',
    borderWidth: 1,
    borderRadius: 5,
    marginBottom: 10,
    paddingHorizontal: 10,
  },
  submitButton: {
    backgroundColor: '#ff5722',
    padding: 10,
    borderRadius: 5,
    marginTop: 10,
  },
  submitButtonText: {
    color: 'white',
    fontWeight: 'bold',
  },
  closeButton: {
    position: 'absolute',
    right: 10,
    top: 10,
  },
  cardTypeContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    marginBottom: 10,
  },
});