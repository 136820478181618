// App.js
import React, { useState, useContext, useEffect } from 'react'
import { StatusBar } from 'expo-status-bar';
import { StyleSheet, View, useWindowDimensions } from 'react-native';
import Login from './components/Login';
import Sidebar from './components/Sidebar';
import Navbar from './components/Navbar';

// pages
import Dashboard from './components/Dashboard';
import Reports from './components/Reports';
import Menu from './components/Menu';
import History from './components/History';
import Reviews from './components/Reviews';
import Catalog from './components/Catalog';
import Support from './components/Support';
import Settings from './components/Settings';
import Marketing from './components/Marketing';
import BankDetails from './components/BankDetails';
import Payments from './components/Payments';


import { AuthProvider, AuthContext } from './contexts/AuthContext';
import { DateProvider } from './contexts/DateContext';
import { PARSE_APP_ID, PARSE_JS_KEY, PARSE_SERVER_URL } from '@env';
import Parse from 'parse';

Parse.initialize(PARSE_APP_ID, PARSE_JS_KEY);
Parse.serverURL = PARSE_SERVER_URL;

const AppContent = () => {
  const { user, setUser, restaurant, setRestaurant } = useContext(AuthContext); // Use AuthContext to get user and setUser
  const [page, setPage] = useState('Dashboard');
  const [isCollapsed, setIsCollapsed] = useState(false); // Add this line

  const { height, width, scale, fontScale } = useWindowDimensions();

  useEffect(() => {
    const parseUser = Parse.User.current();
    if (parseUser) {
      setUser(parseUser);
      // const restaurantId = parseUser.get('restaurant').id;
      // requestGetRestaurant(restaurantId).then((restaurant) => {
      //   setRestaurant(restaurant)
      // });
    }
  }, []);

  const renderPage = () => {
    switch (page) {
      case 'Dashboard':
        return <Dashboard />;
      case 'Reports':
        return <Reports />;
      case 'Menu':
        return <Menu />;
      case 'History':
        return <History />;
      case 'Catalog':
        return <Catalog />;
      case 'Marketing':
        return <Marketing />;
      case 'Support':
        return <Support />;
      case 'Reviews':
        return <Reviews />;
      case 'BankDetails':
        return <BankDetails />;
      case 'Payments':
        return <Payments />;
      case 'Settings':
        return <Settings />;
      default:
        return <Dashboard />;
    }
  };

  if (!user) { // Check if user is null
    return <Login onLogin={(user, restaurant) => { setUser(user); setRestaurant(restaurant); }} />;
  }

  return (
    <DateProvider>
      <View style={styles.container}>
        <StatusBar style="auto" />
        <View style={styles.content}>
          {width > 800 && (
            <Sidebar
              page={page}
              setPage={setPage}
              isCollapsed={isCollapsed}
              setIsCollapsed={setIsCollapsed}
            />
          )}
          <View style={[
            styles.pageContent,
            width > 800 && { paddingLeft: isCollapsed ? 80 : 240 }
          ]}>
            {width <= 800 && <Navbar page={page} setPage={setPage} />}
            {renderPage()}
          </View>
        </View>
      </View>
    </DateProvider>
  );
}

export default function App() {
  return (
    <AuthProvider>
      <AppContent />
    </AuthProvider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    backgroundColor: '#fff',
  },
  content: {
    flex: 1,
    flexDirection: 'row',
  },
  pageContent: {
    flex: 1,
  },
});