import React from 'react';
import { View, Text, StyleSheet } from 'react-native';

const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

export default function OpeningHours({ schedule, handleScheduleChange }) {
  const handleTimeChange = (dayIndex, timeType, value) => {
    const newSchedule = [...schedule];
    newSchedule[dayIndex] = { ...newSchedule[dayIndex], [timeType]: value };
    handleScheduleChange(newSchedule);
  };

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Opening Hours</Text>
      {days.map((day, dayIndex) => {
        const daySchedule = schedule[dayIndex] || { day, open: '', close: '' };
        return (
          <View key={day} style={styles.dayContainer}>
            <Text style={styles.dayText}>{day}</Text>
            <View style={styles.timeContainer}>
              <input
                type="time"
                value={daySchedule.open}
                onChange={(e) => handleTimeChange(dayIndex, 'open', e.target.value)}
                style={styles.timeInput}
              />
              <Text style={styles.toText}>to</Text>
              <input
                type="time"
                value={daySchedule.close}
                onChange={(e) => handleTimeChange(dayIndex, 'close', e.target.value)}
                style={styles.timeInput}
              />
            </View>
          </View>
        );
      })}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginBottom: 20,
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  dayContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  dayText: {
    width: 100,
  },
  timeContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  timeInput: {
    width: 100,
    marginRight: 10,
    padding: 5,
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 4,
  },
  toText: {
    marginHorizontal: 10,
  },
});