import React, { useState, useEffect, useRef } from 'react';
import { StyleSheet, Text, View, FlatList, TextInput, Pressable, Image, KeyboardAvoidingView, Platform, Alert, ActivityIndicator, Modal } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Icon from 'react-native-vector-icons/MaterialIcons';
import colors from '../constants/colors';
import OpenAI from 'openai';

const COLORS = {
  primary: '#0A84FF',
  secondary: '#E9E9EB',
  text: {
    light: '#FFFFFF',
    dark: '#1C1C1E',
  },
  timeStamp: '#8E8E93',
  background: '#FFFFFF',
};

const SupportTicket = ({ ticket, isSelected, onPress }) => (
  <Pressable style={[styles.ticketItem, isSelected && styles.selectedTicket]} onPress={onPress}>
    <Image source={{ uri: ticket.userAvatar }} style={styles.avatar} />
    <View style={styles.ticketInfo}>
      <Text style={styles.userName}>{ticket.userName}</Text>
      <Text numberOfLines={1} style={styles.lastMessage}>{ticket.lastMessage}</Text>
    </View>
    <Text style={styles.timeStamp}>{ticket.time}</Text>
    {ticket.unreadCount > 0 && (
      <View style={styles.unreadBadge}>
        <Text style={styles.unreadCount}>{ticket.unreadCount}</Text>
      </View>
    )}
  </Pressable>
);

const ChatMessage = ({ message, isUser }) => (
  <View style={[styles.messageBubble, isUser ? styles.userMessage : styles.botMessage]}>
    <Text style={[
      styles.messageText,
      isUser ? styles.userMessageText : styles.botMessageText
    ]}>
      {message.text}
    </Text>
    <Text style={styles.messageTime}>{message.time}</Text>
  </View>
);

const TypingIndicator = () => (
  <View style={styles.typingIndicator}>
    <Text style={styles.typingText}>Assistant is typing</Text>
    <ActivityIndicator size="small" color={COLORS.primary} />
  </View>
);

export default function Support() {
  const [tickets, setTickets] = useState([]);
  const [threads, setThreads] = useState({});
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [openai, setOpenai] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isAssistantTyping, setIsAssistantTyping] = useState(false);
  const [isCreatingThread, setIsCreatingThread] = useState(false);
  const [isStatusModalVisible, setIsStatusModalVisible] = useState(false);
  const [activeTab, setActiveTab] = useState('Open');
  const [searchQuery, setSearchQuery] = useState('');
  const flatListRef = useRef(null);

  useEffect(() => {
    const initializeApp = async () => {
      try {
        // Load stored data
        const storedTickets = await AsyncStorage.getItem('tickets');
        const storedThreads = await AsyncStorage.getItem('threads');
        
        if (storedTickets) setTickets(JSON.parse(storedTickets));
        if (storedThreads) setThreads(JSON.parse(storedThreads));

        // Initialize OpenAI
        const openaiInstance = new OpenAI({ 
          apiKey: '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',
          dangerouslyAllowBrowser: true
        });
        setOpenai(openaiInstance);
      } catch (error) {
        console.error('Error initializing app:', error);
        Alert.alert('Error', 'Failed to initialize app. Please try again.');
      }
    };

    initializeApp();
  }, []);

  useEffect(() => {
    // Save tickets and threads whenever they change
    const saveData = async () => {
      try {
        await AsyncStorage.setItem('tickets', JSON.stringify(tickets));
        await AsyncStorage.setItem('threads', JSON.stringify(threads));
      } catch (error) {
        console.error('Error saving data:', error);
      }
    };

    saveData();
  }, [tickets, threads]);

  const createNewThread = async () => {
    setIsCreatingThread(true);
    try {
      const newThread = await openai.beta.threads.create();
      const newTicket = {
        id: Date.now(),
        userName: 'New Conversation',
        userAvatar: 'https://example.com/default-avatar.jpg',
        lastMessage: 'Start a new conversation',
        time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
        unreadCount: 0,
        status: 'Open'
      };
      setTickets(prevTickets => [newTicket, ...prevTickets]);
      setThreads(prevThreads => ({ ...prevThreads, [newTicket.id]: newThread.id }));
      setSelectedTicket(newTicket);
      setMessages([]);
    } catch (error) {
      console.error('Error creating new thread:', error);
      Alert.alert('Error', 'Failed to create a new conversation. Please try again.');
    } finally {
      setIsCreatingThread(false);
    }
  };

  const handleTicketPress = async (ticket) => {
    setSelectedTicket(ticket);
    setMessages([]);
    
    if (threads[ticket.id]) {
      try {
        setIsLoading(true);
        // Load messages for this thread
        const threadMessages = await openai.beta.threads.messages.list(threads[ticket.id]);
        const formattedMessages = threadMessages.data.map(msg => ({
          id: msg.id,
          text: msg.content[0].text.value,
          time: new Date(msg.created_at * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
          isUser: msg.role === 'user'
        }));
        // Reverse the order of messages so the oldest appears first
        setMessages(formattedMessages.reverse());
      } catch (error) {
        console.error('Error loading messages:', error);
        Alert.alert('Error', 'Failed to load conversation history. Please try again.');
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleSendMessage = async () => {
    if (newMessage.trim() && openai && selectedTicket) {
      const threadId = threads[selectedTicket.id];
      const messageToSend = newMessage.trim();
      setIsLoading(true);
      setNewMessage(''); // Clear the input immediately

      try {
        // Add user message to the UI
        const userMessage = {
          id: Date.now(),
          text: messageToSend,
          time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
          isUser: true
        };
        setMessages(prevMessages => [...prevMessages, userMessage]);

        // Send message to OpenAI assistant
        await openai.beta.threads.messages.create(threadId, {
          role: 'user',
          content: messageToSend
        });

        setIsAssistantTyping(true); // Start showing typing indicator

        // Run the assistant
        const run = await openai.beta.threads.runs.create(threadId, {
          assistant_id: 'asst_KPD0Bm30bdquH4b2I0xRYusx'
        });

        // Wait for the run to complete
        let runStatus = await openai.beta.threads.runs.retrieve(threadId, run.id);
        while (runStatus.status !== 'completed') {
          await new Promise(resolve => setTimeout(resolve, 1000));
          runStatus = await openai.beta.threads.runs.retrieve(threadId, run.id);
        }

        // Retrieve the assistant's response
        const threadMessages = await openai.beta.threads.messages.list(threadId);
        const assistantMessage = threadMessages.data[0];

        setIsAssistantTyping(false); // Stop showing typing indicator

        // Add assistant message to the UI
        setMessages(prevMessages => [...prevMessages, {
          id: prevMessages.length + 2,
          text: assistantMessage.content[0].text.value,
          time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
          isUser: false
        }]);

      } catch (error) {
        console.error('Error sending message:', error);
        Alert.alert('Error', 'Failed to send message. Please try again.');
        setIsAssistantTyping(false); // Ensure typing indicator is hidden on error
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.nativeEvent.key === 'Enter' && !e.nativeEvent.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const handleStatusChange = (newStatus) => {
    if (selectedTicket) {
      const updatedTicket = { ...selectedTicket, status: newStatus };
      setSelectedTicket(updatedTicket);
      setTickets(prevTickets => 
        prevTickets.map(ticket => 
          ticket.id === selectedTicket.id ? updatedTicket : ticket
        )
      );
      setIsStatusModalVisible(false);

      // Log to confirm the change
      console.log(`Changed ticket ${selectedTicket.id} status to ${newStatus}`);
    }
  };

  const filteredTickets = tickets.filter(ticket => {
    const matchesTab = activeTab === 'All' || ticket.status === activeTab;
    const matchesSearch = ticket.userName.toLowerCase().includes(searchQuery.toLowerCase()) ||
                          ticket.lastMessage.toLowerCase().includes(searchQuery.toLowerCase());
    return matchesTab && matchesSearch;
  });

  const handleSearch = (text) => {
    setSearchQuery(text);
  };

  useEffect(() => {
    // Scroll to the bottom when messages change
    if (flatListRef.current) {
      flatListRef.current.scrollToEnd({ animated: true });
    }
  }, [messages]);

  const StatusModal = () => (
    <Modal
      animationType="slide"
      transparent={true}
      visible={isStatusModalVisible}
      onRequestClose={() => setIsStatusModalVisible(false)}
    >
      <View style={styles.modalOverlay}>
        <View style={styles.modalContent}>
          <Text style={styles.modalTitle}>Change Status</Text>
          <Pressable
            style={styles.statusOption}
            onPress={() => handleStatusChange('Open')}
          >
            <Icon 
              name={selectedTicket?.status === 'Open' ? 'radio-button-checked' : 'radio-button-unchecked'} 
              size={24} 
              color={colors.primary} 
            />
            <Text style={styles.statusOptionText}>Open</Text>
          </Pressable>
          <Pressable
            style={styles.statusOption}
            onPress={() => handleStatusChange('Resolved')}
          >
            <Icon 
              name={selectedTicket?.status === 'Resolved' ? 'radio-button-checked' : 'radio-button-unchecked'} 
              size={24} 
              color={colors.primary} 
            />
            <Text style={styles.statusOptionText}>Resolved</Text>
          </Pressable>
          <Pressable
            style={styles.closeButton}
            onPress={() => setIsStatusModalVisible(false)}
          >
            <Text style={styles.closeButtonText}>Cancel</Text>
          </Pressable>
        </View>
      </View>
    </Modal>
  );

  return (
    <KeyboardAvoidingView 
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      style={styles.container}
      keyboardVerticalOffset={Platform.OS === "ios" ? 90 : 0}
    >
      <View style={styles.leftPanel}>
        <View style={styles.header}>
          <Text style={styles.headerText}>Support Chat</Text>
        </View>
        <View style={styles.tabContainer}>
          <Pressable 
            style={[styles.tab, activeTab === 'Open' && styles.activeTab]}
            onPress={() => setActiveTab('Open')}
          >
            <Text>Open <Text style={styles.badge}>{tickets.filter(t => t.status === 'Open').length}</Text></Text>
          </Pressable>
          <Pressable 
            style={[styles.tab, activeTab === 'All' && styles.activeTab]}
            onPress={() => setActiveTab('All')}
          >
            <Text>All</Text>
          </Pressable>
          <Pressable 
            style={[styles.tab, activeTab === 'Resolved' && styles.activeTab]}
            onPress={() => setActiveTab('Resolved')}
          >
            <Text>Resolved</Text>
          </Pressable>
        </View>
        <View style={styles.searchContainer}>
          <Icon name="search" size={20} color={colors.mediumGrey} />
          <TextInput
            style={styles.searchInput}
            placeholder="Search"
            placeholderTextColor={colors.mediumGrey}
            value={searchQuery}
            onChangeText={handleSearch}
          />
        </View>
        <FlatList
          data={filteredTickets}
          renderItem={({ item }) => (
            <SupportTicket
              ticket={item}
              isSelected={selectedTicket && selectedTicket.id === item.id}
              onPress={() => handleTicketPress(item)}
            />
          )}
          keyExtractor={(item) => item.id.toString()}
        />
      </View>
      <View style={styles.rightPanel}>
        {selectedTicket ? (
          <>
            <View style={styles.chatHeader}>
              <Text style={styles.chatHeaderText}>{selectedTicket.userName}</Text>
              <Pressable 
                style={styles.statusButton}
                onPress={() => setIsStatusModalVisible(true)}
              >
                <Text style={styles.statusButtonText}>{selectedTicket.status}</Text>
                <Icon name="arrow-drop-down" size={24} color={colors.white} />
              </Pressable>
            </View>
            <View style={styles.chatContainer}>
              {isLoading ? (
                <ActivityIndicator size="large" color={colors.primary} />
              ) : (
                <FlatList
                  ref={flatListRef}
                  data={messages}
                  renderItem={({ item }) => <ChatMessage message={item} isUser={item.isUser} />}
                  keyExtractor={(item) => item.id.toString()}
                  style={styles.chatMessages}
                  contentContainerStyle={styles.chatMessagesContent}
                  ListFooterComponent={isAssistantTyping ? <TypingIndicator /> : null}
                />
              )}
            </View>
            <View style={styles.inputContainer}>
              <TextInput
                style={styles.input}
                value={newMessage}
                onChangeText={setNewMessage}
                placeholder="Type Here..."
                placeholderTextColor={colors.mediumGrey}
                editable={!isLoading}
                onKeyPress={handleKeyPress}
                multiline
              />
              <Pressable 
                style={[styles.sendButton, isLoading && styles.disabledButton]} 
                onPress={handleSendMessage}
                disabled={isLoading}
              >
                <Icon name="send" size={24} color={colors.white} />
              </Pressable>
            </View>
          </>
        ) : (
          <View style={styles.noTicketSelected}>
            <Text style={styles.noTicketText}>Select a ticket to view the conversation</Text>
            <Pressable 
              style={styles.newThreadButton} 
              onPress={createNewThread}
              disabled={isCreatingThread}
            >
              {isCreatingThread ? (
                <ActivityIndicator size="small" color={colors.white} />
              ) : (
                <>
                  <Icon name="add" size={24} color={colors.white} />
                  <Text style={styles.newThreadButtonText}>New Conversation</Text>
                </>
              )}
            </Pressable>
          </View>
        )}
      </View>
      <StatusModal />
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: colors.white,
  },
  leftPanel: {
    width: '30%',
    borderRightWidth: 1,
    borderRightColor: colors.lightGrey,
  },
  rightPanel: {
    flex: 1,
    flexDirection: 'column',
  },
  header: {
    padding: 16,
    borderBottomWidth: 1,
    borderBottomColor: colors.lightGrey,
  },
  headerText: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  tabContainer: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: colors.lightGrey,
  },
  tab: {
    flex: 1,
    padding: 16,
    alignItems: 'center',
  },
  activeTab: {
    borderBottomWidth: 2,
    borderBottomColor: colors.primary,
  },
  badge: {
    backgroundColor: colors.primary,
    color: colors.white,
    borderRadius: 10,
    paddingHorizontal: 6,
    fontSize: 12,
  },
  searchContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 8,
    backgroundColor: colors.lightBackground,
    margin: 8,
    borderRadius: 4,
  },
  searchInput: {
    flex: 1,
    marginLeft: 8,
    color: colors.darkGrey,
  },
  ticketItem: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 16,
    borderBottomWidth: 1,
    borderBottomColor: colors.lightGrey,
  },
  selectedTicket: {
    backgroundColor: colors.lightBackground,
  },
  avatar: {
    width: 40,
    height: 40,
    borderRadius: 20,
    marginRight: 12,
  },
  ticketInfo: {
    flex: 1,
  },
  userName: {
    fontWeight: 'bold',
    marginBottom: 4,
  },
  lastMessage: {
    color: colors.mediumGrey,
  },
  timeStamp: {
    fontSize: 12,
    color: colors.mediumGrey,
  },
  unreadBadge: {
    backgroundColor: colors.primary,
    borderRadius: 10,
    width: 20,
    height: 20,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 8,
  },
  unreadCount: {
    color: colors.white,
    fontSize: 12,
  },
  chatHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 16,
    borderBottomWidth: 1,
    borderBottomColor: colors.lightGrey,
  },
  chatHeaderText: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  statusButton: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colors.primary,
    paddingVertical: 8,
    paddingHorizontal: 12,
    borderRadius: 4,
  },
  statusButtonText: {
    color: colors.white,
    marginRight: 4,
  },
  chatContainer: {
    flex: 1,
  },
  chatMessages: {
    flex: 1,
  },
  chatMessagesContent: {
    flexGrow: 1,
    justifyContent: 'flex-end',
    paddingVertical: 16,
  },
  messageBubble: {
    maxWidth: '75%',
    padding: 12,
    borderRadius: 18,
    marginVertical: 8,
    marginHorizontal: 16,
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.15,
    shadowRadius: 1,
    elevation: 2,
  },
  userMessage: {
    alignSelf: 'flex-end',
    backgroundColor: COLORS.primary,
    borderBottomRightRadius: 4,
  },
  botMessage: {
    alignSelf: 'flex-start',
    backgroundColor: COLORS.secondary,
    borderBottomLeftRadius: 4,
  },
  messageText: {
    fontSize: 16,
    marginBottom: 4,
  },
  userMessageText: {
    color: COLORS.text.light,
  },
  botMessageText: {
    color: COLORS.text.dark,
  },
  messageTime: {
    fontSize: 11,
    color: COLORS.timeStamp,
    alignSelf: 'flex-end',
  },
  inputContainer: {
    flexDirection: 'row',
    padding: 8,
    borderTopWidth: 1,
    borderTopColor: colors.lightGrey,
  },
  input: {
    flex: 1,
    backgroundColor: colors.lightBackground,
    borderRadius: 20,
    paddingHorizontal: 16,
    paddingVertical: 8,
    marginRight: 8,
    verticalAlign: 'middle',
    userSelect: 'text',
  },
  sendButton: {
    backgroundColor: colors.primary,
    borderRadius: 20,
    width: 40,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
  },
  noTicketSelected: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  noTicketText: {
    fontSize: 16,
    color: colors.darkGrey,
    marginBottom: 20,
  },
  newThreadButton: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colors.primary,
    paddingVertical: 12,
    paddingHorizontal: 20,
    borderRadius: 25,
  },
  newThreadButtonText: {
    color: colors.white,
    fontSize: 16,
    fontWeight: 'bold',
    marginLeft: 8,
  },
  disabledButton: {
    opacity: 0.5,
  },
  typingIndicator: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
    marginLeft: 10,
  },
  typingText: {
    marginRight: 10,
    color: COLORS.timeStamp,
    fontSize: 12,
  },
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    backgroundColor: colors.white,
    borderRadius: 10,
    padding: 20,
    alignItems: 'center',
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5
  },
  modalTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 15,
  },
  statusOption: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
    width: '100%',
  },
  statusOptionText: {
    marginLeft: 10,
    fontSize: 16,
  },
  closeButton: {
    marginTop: 20,
    padding: 10,
  },
  closeButtonText: {
    color: colors.primary,
    fontSize: 16,
  },
});