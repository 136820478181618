import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import colors from '../constants/colors';

const MetricCard = ({ title, value }) => (
  <View style={styles.card}>
    <Text style={styles.cardTitle}>{title}</Text>
    <Text style={styles.cardValue}>{value}</Text>
  </View>
);

export default function ReviewMetrics({ reviews }) {
  const totalReviews = reviews.length;
  const avgRating = reviews.reduce((sum, review) => sum + calculateAverageRating(review.review), 0) / totalReviews;
  const fiveStarReviews = reviews.filter(review => calculateAverageRating(review.review) === 5).length;
  const fiveStarPercentage = (fiveStarReviews / totalReviews * 100).toFixed(1);

  return (
    <View style={styles.container}>
      <MetricCard title="Total Reviews" value={totalReviews} />
      <MetricCard title="Average Rating" value={avgRating.toFixed(1)} />
      <MetricCard title="5-Star Reviews" value={`${fiveStarPercentage}%`} />
    </View>
  );
}

const calculateAverageRating = (review) => {
  const validRatings = ['restaurant', 'driver', 'service', 'uva']
    .map(category => review[category].star)
    .filter(star => star > 0);
  
  return validRatings.length > 0 ? validRatings.reduce((a, b) => a + b) / validRatings.length : 0;
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  card: {
    backgroundColor: colors.white,
    borderRadius: 10,
    padding: 15,
    flex: 1,
    marginHorizontal: 5,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 3,
  },
  cardTitle: {
    fontSize: 14,
    color: colors.mediumGrey,
    marginBottom: 5,
  },
  cardValue: {
    fontSize: 18,
    fontWeight: 'bold',
    color: colors.darkGrey,
  },
});