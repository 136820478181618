import React, { useState, useRef, useEffect } from 'react';
import { StyleSheet, Text, View, ScrollView, Pressable, TextInput, Modal, Dimensions, Animated } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import colors from '../constants/colors';

const { width, height } = Dimensions.get('window');
const MODAL_WIDTH = width * 0.4;

const MetricCard = ({ title, value }) => (
  <View style={styles.metricCard}>
    <Text style={styles.metricTitle}>{title}</Text>
    <Text style={styles.metricValue}>{value}</Text>
  </View>
);

const CampaignCard = ({ title, description, onPress }) => (
  <View style={styles.campaignCard}>
    <Text style={styles.campaignTitle}>{title}</Text>
    <Text style={styles.campaignDescription}>{description}</Text>
    <Pressable style={styles.selectButton} onPress={onPress}>
      <Text style={styles.selectButtonText}>Select Promotion</Text>
    </Pressable>
  </View>
);

const CampaignRow = ({ campaign }) => (
  <View style={styles.campaignRow}>
    <Text style={styles.campaignCell}>{campaign.promotion}</Text>
    <Text style={[styles.campaignCell, styles.greenText]}>{campaign.impressions} <Icon name="arrow-upward" size={12} color={colors.success} /></Text>
    <Text style={[styles.campaignCell, styles.greenText]}>{campaign.views} <Icon name="arrow-upward" size={12} color={colors.success} /></Text>
    <Text style={styles.campaignCell}>{campaign.clicks}</Text>
    <Text style={styles.campaignCell}>{campaign.ctr}</Text>
    <Text style={styles.campaignCell}>{campaign.cpc}</Text>
    <Text style={styles.campaignCell}>{campaign.cost}</Text>
    <Text style={styles.campaignCell}>{campaign.redemption}</Text>
    <Text style={styles.campaignCell}>{campaign.budget}</Text>
    <Text style={styles.campaignCell}>{campaign.date}</Text>
  </View>
);

const DiscountModal = ({ isVisible, onClose, onSave }) => {
  const [promotionName, setPromotionName] = useState('');
  const [minimumOrderPrice, setMinimumOrderPrice] = useState('');
  const [discountAmount, setDiscountAmount] = useState('');
  const [promoCode, setPromoCode] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [paymentDetails, setPaymentDetails] = useState('');

  const slideAnim = useRef(new Animated.Value(MODAL_WIDTH)).current;
  const fadeAnim = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    if (isVisible) {
      Animated.parallel([
        Animated.timing(slideAnim, {
          toValue: 0,
          duration: 300,
          useNativeDriver: true,
        }),
        Animated.timing(fadeAnim, {
          toValue: 1,
          duration: 300,
          useNativeDriver: true,
        }),
      ]).start();
    } else {
      Animated.parallel([
        Animated.timing(slideAnim, {
          toValue: MODAL_WIDTH,
          duration: 300,
          useNativeDriver: true,
        }),
        Animated.timing(fadeAnim, {
          toValue: 0,
          duration: 300,
          useNativeDriver: true,
        }),
      ]).start();
    }
  }, [isVisible]);

  const handleSave = () => {
    onSave({
      promotionName,
      minimumOrderPrice,
      discountAmount,
      promoCode,
      startDate,
      endDate,
      paymentDetails,
    });
    onClose();
  };

  return (
    <Modal
      visible={isVisible}
      transparent={true}
      animationType="none"
    >
      <Animated.View 
        style={[
          styles.modalOverlay,
          {
            opacity: fadeAnim,
          },
        ]}
      >
        <Pressable style={styles.modalOverlayPressable} onPress={onClose} />
        <Animated.View 
          style={[
            styles.modalContent,
            {
              transform: [{ translateX: slideAnim }],
            },
          ]}
        >
          <View style={styles.modalHeader}>
            <Text style={styles.modalTitle}>Discount</Text>
            <Pressable onPress={onClose}>
              <Icon name="close" size={24} color={colors.darkGrey} />
            </Pressable>
          </View>
          <ScrollView style={styles.modalBody}>
            <View style={styles.inputGroup}>
              <Text style={styles.inputLabel}>Promotion Name</Text>
              <TextInput
                style={styles.input}
                value={promotionName}
                onChangeText={setPromotionName}
                placeholder="San Juan Special"
              />
            </View>
            <View style={styles.inputGroup}>
              <Text style={styles.inputLabel}>Minimum Order Price</Text>
              <TextInput
                style={styles.input}
                value={minimumOrderPrice}
                onChangeText={setMinimumOrderPrice}
                placeholder="$ 20.00"
                keyboardType="numeric"
              />
            </View>
            <View style={styles.inputGroup}>
              <Text style={styles.inputLabel}>Discount Amount</Text>
              <TextInput
                style={styles.input}
                value={discountAmount}
                onChangeText={setDiscountAmount}
                placeholder="$ 3.00"
                keyboardType="numeric"
              />
            </View>
            <View style={styles.inputGroup}>
              <Text style={styles.inputLabel}>Promotion Type</Text>
              <View style={styles.promotionTypeContainer}>
                <Text style={styles.promotionTypeText}>Wide Promotion</Text>
                <Pressable style={styles.promoCodeButton}>
                  <Text style={styles.promoCodeButtonText}>Promo Code</Text>
                </Pressable>
              </View>
              <TextInput
                style={styles.input}
                value={promoCode}
                onChangeText={setPromoCode}
                placeholder="UVASANJUAN!"
              />
            </View>
            <View style={styles.inputGroup}>
              <Text style={styles.inputLabel}>Promotion Run</Text>
              <View style={styles.dateContainer}>
                <TextInput
                  style={[styles.input, styles.dateInput]}
                  value={startDate}
                  onChangeText={setStartDate}
                  placeholder="08-12-2021"
                />
                <Text style={styles.dateSeparator}>-</Text>
                <TextInput
                  style={[styles.input, styles.dateInput]}
                  value={endDate}
                  onChangeText={setEndDate}
                  placeholder="08-22-2021"
                />
              </View>
            </View>
            <View style={styles.inputGroup}>
              <Text style={styles.inputLabel}>Payment Details</Text>
              <TextInput
                style={styles.input}
                value={paymentDetails}
                onChangeText={setPaymentDetails}
                placeholder="**** **** **** 1256"
              />
            </View>
          </ScrollView>
          <View style={styles.modalFooter}>
            <Pressable style={styles.cancelButton} onPress={onClose}>
              <Text style={styles.cancelButtonText}>Cancel</Text>
            </Pressable>
            <Pressable style={styles.saveButton} onPress={handleSave}>
              <Text style={styles.saveButtonText}>Start Promotion</Text>
            </Pressable>
          </View>
        </Animated.View>
      </Animated.View>
    </Modal>
  );
};

const FreeItemModal = ({ isVisible, onClose, onSave }) => {
  const [promotionName, setPromotionName] = useState('');
  const [minimumOrderPrice, setMinimumOrderPrice] = useState('');
  const [promoCode, setPromoCode] = useState('');
  const [mainItems, setMainItems] = useState([{ name: '' }]);
  const [freeItem, setFreeItem] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [paymentDetails, setPaymentDetails] = useState('');

  const slideAnim = useRef(new Animated.Value(MODAL_WIDTH)).current;
  const fadeAnim = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    if (isVisible) {
      Animated.parallel([
        Animated.timing(slideAnim, {
          toValue: 0,
          duration: 300,
          useNativeDriver: true,
        }),
        Animated.timing(fadeAnim, {
          toValue: 1,
          duration: 300,
          useNativeDriver: true,
        }),
      ]).start();
    } else {
      Animated.parallel([
        Animated.timing(slideAnim, {
          toValue: MODAL_WIDTH,
          duration: 300,
          useNativeDriver: true,
        }),
        Animated.timing(fadeAnim, {
          toValue: 0,
          duration: 300,
          useNativeDriver: true,
        }),
      ]).start();
    }
  }, [isVisible]);

  const handleSave = () => {
    onSave({
      promotionName,
      minimumOrderPrice,
      promoCode,
      mainItems,
      freeItem,
      startDate,
      endDate,
      paymentDetails,
    });
    onClose();
  };

  const addMainItem = () => {
    setMainItems([...mainItems, { name: '' }]);
  };

  const removeMainItem = (index) => {
    const updatedItems = mainItems.filter((_, i) => i !== index);
    setMainItems(updatedItems);
  };

  return (
    <Modal
      visible={isVisible}
      transparent={true}
      animationType="none"
    >
      <Animated.View 
        style={[
          styles.modalOverlay,
          {
            opacity: fadeAnim,
          },
        ]}
      >
        <Pressable style={styles.modalOverlayPressable} onPress={onClose} />
        <Animated.View 
          style={[
            styles.modalContent,
            {
              transform: [{ translateX: slideAnim }],
            },
          ]}
        >
          <View style={styles.modalHeader}>
            <Text style={styles.modalTitle}>Free Item</Text>
            <Pressable onPress={onClose}>
              <Icon name="close" size={24} color={colors.darkGrey} />
            </Pressable>
          </View>
          <ScrollView style={styles.modalBody}>
            <View style={styles.inputGroup}>
              <Text style={styles.inputLabel}>Promotion Name</Text>
              <TextInput
                style={styles.input}
                value={promotionName}
                onChangeText={setPromotionName}
                placeholder="San Juan Special"
              />
            </View>
            <View style={styles.inputGroup}>
              <Text style={styles.inputLabel}>Minimum Order Price</Text>
              <TextInput
                style={styles.input}
                value={minimumOrderPrice}
                onChangeText={setMinimumOrderPrice}
                placeholder="$ 20.00"
                keyboardType="numeric"
              />
            </View>
            <View style={styles.inputGroup}>
              <Text style={styles.inputLabel}>Promotion Type</Text>
              <View style={styles.promotionTypeContainer}>
                <Text style={styles.promotionTypeText}>Wide Promotion</Text>
                <Pressable style={styles.promoCodeButton}>
                  <Text style={styles.promoCodeButtonText}>Promo Code</Text>
                </Pressable>
              </View>
              <TextInput
                style={styles.input}
                value={promoCode}
                onChangeText={setPromoCode}
                placeholder="UVASANJUAN!"
              />
            </View>
            <View style={styles.inputGroup}>
              <Text style={styles.inputLabel}>Main Item(s)</Text>
              {mainItems.map((item, index) => (
                <View key={index} style={styles.mainItemContainer}>
                  <TextInput
                    style={[styles.input, styles.mainItemInput]}
                    value={item.name}
                    onChangeText={(text) => {
                      const updatedItems = [...mainItems];
                      updatedItems[index].name = text;
                      setMainItems(updatedItems);
                    }}
                    placeholder="Select Item"
                  />
                  <Pressable onPress={() => removeMainItem(index)}>
                    <Icon name="delete" size={24} color={colors.danger} />
                  </Pressable>
                </View>
              ))}
              <Pressable style={styles.addButton} onPress={addMainItem}>
                <Text style={styles.addButtonText}>Add Another</Text>
              </Pressable>
            </View>
            <View style={styles.inputGroup}>
              <Text style={styles.inputLabel}>Free Item</Text>
              <TextInput
                style={styles.input}
                value={freeItem}
                onChangeText={setFreeItem}
                placeholder="Coffee"
              />
            </View>
            <View style={styles.inputGroup}>
              <Text style={styles.inputLabel}>Promotion Run</Text>
              <View style={styles.dateContainer}>
                <TextInput
                  style={[styles.input, styles.dateInput]}
                  value={startDate}
                  onChangeText={setStartDate}
                  placeholder="08-12-2021"
                />
                <Text style={styles.dateSeparator}>-</Text>
                <TextInput
                  style={[styles.input, styles.dateInput]}
                  value={endDate}
                  onChangeText={setEndDate}
                  placeholder="08-22-2021"
                />
              </View>
            </View>
            <View style={styles.inputGroup}>
              <Text style={styles.inputLabel}>Payment Details</Text>
              <TextInput
                style={styles.input}
                value={paymentDetails}
                onChangeText={setPaymentDetails}
                placeholder="**** **** **** 1256"
              />
            </View>
          </ScrollView>
          <View style={styles.modalFooter}>
            <Pressable style={styles.cancelButton} onPress={onClose}>
              <Text style={styles.cancelButtonText}>Cancel</Text>
            </Pressable>
            <Pressable style={styles.saveButton} onPress={handleSave}>
              <Text style={styles.saveButtonText}>Start Promotion</Text>
            </Pressable>
          </View>
        </Animated.View>
      </Animated.View>
    </Modal>
  );
};

const FreeDeliveryModal = ({ isVisible, onClose, onSave }) => {
  const [promotionName, setPromotionName] = useState('');
  const [minimumOrderPrice, setMinimumOrderPrice] = useState('');
  const [promoCode, setPromoCode] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [paymentDetails, setPaymentDetails] = useState('');

  const slideAnim = useRef(new Animated.Value(MODAL_WIDTH)).current;
  const fadeAnim = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    if (isVisible) {
      Animated.parallel([
        Animated.timing(slideAnim, {
          toValue: 0,
          duration: 300,
          useNativeDriver: true,
        }),
        Animated.timing(fadeAnim, {
          toValue: 1,
          duration: 300,
          useNativeDriver: true,
        }),
      ]).start();
    } else {
      Animated.parallel([
        Animated.timing(slideAnim, {
          toValue: MODAL_WIDTH,
          duration: 300,
          useNativeDriver: true,
        }),
        Animated.timing(fadeAnim, {
          toValue: 0,
          duration: 300,
          useNativeDriver: true,
        }),
      ]).start();
    }
  }, [isVisible]);

  const handleSave = () => {
    onSave({
      promotionName,
      minimumOrderPrice,
      promoCode,
      startDate,
      endDate,
      paymentDetails,
    });
    onClose();
  };

  return (
    <Modal
      visible={isVisible}
      transparent={true}
      animationType="none"
    >
      <Animated.View 
        style={[
          styles.modalOverlay,
          {
            opacity: fadeAnim,
          },
        ]}
      >
        <Pressable style={styles.modalOverlayPressable} onPress={onClose} />
        <Animated.View 
          style={[
            styles.modalContent,
            {
              transform: [{ translateX: slideAnim }],
            },
          ]}
        >
          <View style={styles.modalHeader}>
            <Text style={styles.modalTitle}>Free Delivery</Text>
            <Pressable onPress={onClose}>
              <Icon name="close" size={24} color={colors.darkGrey} />
            </Pressable>
          </View>
          <ScrollView style={styles.modalBody}>
            <View style={styles.inputGroup}>
              <Text style={styles.inputLabel}>Promotion Name</Text>
              <TextInput
                style={styles.input}
                value={promotionName}
                onChangeText={setPromotionName}
                placeholder="San Juan Special"
              />
            </View>
            <View style={styles.inputGroup}>
              <Text style={styles.inputLabel}>Minimum Order Price</Text>
              <TextInput
                style={styles.input}
                value={minimumOrderPrice}
                onChangeText={setMinimumOrderPrice}
                placeholder="$ 20.00"
                keyboardType="numeric"
              />
            </View>
            <View style={styles.inputGroup}>
              <Text style={styles.inputLabel}>Promotion Type</Text>
              <View style={styles.promotionTypeContainer}>
                <Text style={styles.promotionTypeText}>Wide Promotion</Text>
                <Pressable style={styles.promoCodeButton}>
                  <Text style={styles.promoCodeButtonText}>Promo Code</Text>
                </Pressable>
              </View>
              <TextInput
                style={styles.input}
                value={promoCode}
                onChangeText={setPromoCode}
                placeholder="UVASANJUAN!"
              />
            </View>
            <View style={styles.inputGroup}>
              <Text style={styles.inputLabel}>Promotion Run</Text>
              <View style={styles.dateContainer}>
                <TextInput
                  style={[styles.input, styles.dateInput]}
                  value={startDate}
                  onChangeText={setStartDate}
                  placeholder="08-12-2021"
                />
                <Text style={styles.dateSeparator}>-</Text>
                <TextInput
                  style={[styles.input, styles.dateInput]}
                  value={endDate}
                  onChangeText={setEndDate}
                  placeholder="08-22-2021"
                />
              </View>
            </View>
            <View style={styles.inputGroup}>
              <Text style={styles.inputLabel}>Payment Details</Text>
              <TextInput
                style={styles.input}
                value={paymentDetails}
                onChangeText={setPaymentDetails}
                placeholder="**** **** **** 1256"
              />
            </View>
          </ScrollView>
          <View style={styles.modalFooter}>
            <Pressable style={styles.cancelButton} onPress={onClose}>
              <Text style={styles.cancelButtonText}>Cancel</Text>
            </Pressable>
            <Pressable style={styles.saveButton} onPress={handleSave}>
              <Text style={styles.saveButtonText}>Start Promotion</Text>
            </Pressable>
          </View>
        </Animated.View>
      </Animated.View>
    </Modal>
  );
};

export default function Marketing() {
  const [selectedRestaurant, setSelectedRestaurant] = useState('Choose a Restaurant');

  const metrics = [
    { title: 'Active Campaigns', value: '3' },
    { title: 'Scheduled Campaigns', value: '12,500' },
    { title: 'Total Campaigns', value: '1506' },
    { title: 'Total Cost', value: '$7530' },
  ];

  const campaignTypes = [
    { title: 'Discount', description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ac quam non neque rutrum pretium auctor nec metus.' },
    { title: 'Free Item', description: 'Maecenas placerat varius tellus sit amet semper. In aliquam vel dolor sit amet hendrerit. In hac habitasse platea dictumst. Pellentesque nunc justo.' },
    { title: 'Free Delivery', description: 'Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nullam tristique tempor sapien, sit amet sagittis nulla.' },
  ];

  const activeCampaigns = [
    { promotion: 'San Juan Special', impressions: '156,558', views: '36,965', clicks: '136', ctr: '80%', cpc: '$2.35', cost: '$680', redemption: '$2000', budget: '$1000', date: '02/13/2021 - 02/23/2021' },
    { promotion: 'San Juan Special', impressions: '156,558', views: '36,965', clicks: '136', ctr: '80%', cpc: '$2.35', cost: '$680', redemption: '$2000', budget: '$1000', date: '02/13/2021 - 02/23/2021' },
    { promotion: 'San Juan Special', impressions: '156,558', views: '36,965', clicks: '136', ctr: '80%', cpc: '$2.35', cost: '$680', redemption: '$2000', budget: '$1000', date: '02/13/2021 - 02/23/2021' },
  ];

  const [isDiscountModalVisible, setIsDiscountModalVisible] = useState(false);
  const [isFreeItemModalVisible, setIsFreeItemModalVisible] = useState(false);
  const [isFreeDeliveryModalVisible, setIsFreeDeliveryModalVisible] = useState(false);

  const handleDiscountSelect = () => {
    setIsDiscountModalVisible(true);
  };

  const handleFreeItemSelect = () => {
    setIsFreeItemModalVisible(true);
  };

  const handleFreeDeliverySelect = () => {
    setIsFreeDeliveryModalVisible(true);
  };

  const handleSaveDiscount = (discountData) => {
    // Implement logic to save the discount data
    console.log('Discount data:', discountData);
  };

  const handleSaveFreeItem = (freeItemData) => {
    // Implement logic to save the free item data
    console.log('Free Item data:', freeItemData);
  };

  const handleSaveFreeDelivery = (freeDeliveryData) => {
    // Implement logic to save the free delivery data
    console.log('Free Delivery data:', freeDeliveryData);
  };

  return (
    <ScrollView style={styles.container}>
      <View style={styles.header}>
        <Text style={styles.headerText}>Marketing</Text>
        <Pressable style={styles.restaurantSelector}>
          <Text>{selectedRestaurant}</Text>
          <Icon name="arrow-drop-down" size={24} color={colors.darkGrey} />
        </Pressable>
      </View>

      <View style={styles.metricsContainer}>
        {metrics.map((metric, index) => (
          <MetricCard key={index} title={metric.title} value={metric.value} />
        ))}
      </View>

      <Text style={styles.sectionTitle}>Start New Campaign</Text>
      <View style={styles.campaignTypesContainer}>
        {campaignTypes.map((campaign, index) => (
          <CampaignCard
            key={index}
            title={campaign.title}
            description={campaign.description}
            onPress={
              campaign.title === 'Discount'
                ? handleDiscountSelect
                : campaign.title === 'Free Item'
                ? handleFreeItemSelect
                : campaign.title === 'Free Delivery'
                ? handleFreeDeliverySelect
                : null
            }
          />
        ))}
      </View>

      <Text style={styles.sectionTitle}>Active Campaigns</Text>
      <ScrollView horizontal style={styles.tableContainer}>
        <View>
          <View style={styles.tableHeader}>
            <Text style={styles.headerCell}>Promotion</Text>
            <Text style={styles.headerCell}>Impressions</Text>
            <Text style={styles.headerCell}>Views</Text>
            <Text style={styles.headerCell}>Clicks</Text>
            <Text style={styles.headerCell}>CTR</Text>
            <Text style={styles.headerCell}>CPC</Text>
            <Text style={styles.headerCell}>Cost</Text>
            <Text style={styles.headerCell}>Redemption</Text>
            <Text style={styles.headerCell}>Budget</Text>
            <Text style={styles.headerCell}>Start / End Date</Text>
          </View>
          {activeCampaigns.map((campaign, index) => (
            <CampaignRow key={index} campaign={campaign} />
          ))}
        </View>
      </ScrollView>

      <DiscountModal
        isVisible={isDiscountModalVisible}
        onClose={() => setIsDiscountModalVisible(false)}
        onSave={handleSaveDiscount}
      />
      <FreeItemModal
        isVisible={isFreeItemModalVisible}
        onClose={() => setIsFreeItemModalVisible(false)}
        onSave={handleSaveFreeItem}
      />
      <FreeDeliveryModal
        isVisible={isFreeDeliveryModalVisible}
        onClose={() => setIsFreeDeliveryModalVisible(false)}
        onSave={handleSaveFreeDelivery}
      />
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
    padding: 16,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 16,
  },
  headerText: {
    fontSize: 24,
    fontWeight: 'bold',
    color: colors.darkGrey,
  },
  restaurantSelector: {
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: colors.lightGrey,
    borderRadius: 4,
    padding: 8,
  },
  metricsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 24,
  },
  metricCard: {
    backgroundColor: colors.white,
    borderRadius: 8,
    padding: 16,
    shadowColor: colors.darkGrey,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 3,
    flex: 1,
    marginHorizontal: 4,
  },
  metricTitle: {
    fontSize: 14,
    color: colors.mediumGrey,
    marginBottom: 8,
  },
  metricValue: {
    fontSize: 24,
    fontWeight: 'bold',
    color: colors.darkGrey,
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    color: colors.darkGrey,
    marginBottom: 16,
  },
  campaignTypesContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 24,
  },
  campaignCard: {
    backgroundColor: colors.white,
    borderRadius: 8,
    padding: 16,
    shadowColor: colors.darkGrey,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 3,
    flex: 1,
    marginHorizontal: 8,
  },
  campaignTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    color: colors.darkGrey,
    marginBottom: 8,
  },
  campaignDescription: {
    fontSize: 14,
    color: colors.mediumGrey,
    marginBottom: 16,
  },
  selectButton: {
    backgroundColor: colors.primary,
    borderRadius: 4,
    padding: 8,
    alignItems: 'center',
  },
  selectButtonText: {
    color: colors.white,
    fontWeight: 'bold',
  },
  tableContainer: {
    marginBottom: 24,
  },
  tableHeader: {
    flexDirection: 'row',
    backgroundColor: colors.lightBackground,
    paddingVertical: 8,
  },
  headerCell: {
    width: 120,
    fontWeight: 'bold',
    color: colors.darkGrey,
    paddingHorizontal: 8,
  },
  campaignRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: colors.lightGrey,
    paddingVertical: 8,
  },
  campaignCell: {
    width: 120,
    color: colors.darkGrey,
    paddingHorizontal: 8,
  },
  greenText: {
    color: colors.success,
  },
  modalOverlay: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    width: MODAL_WIDTH,
    height: '100%',
    backgroundColor: colors.white,
  },
  modalHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 16,
    borderBottomWidth: 1,
    borderBottomColor: colors.lightGrey,
  },
  modalTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    color: colors.darkGrey,
  },
  modalBody: {
    flex: 1,
    padding: 16,
  },
  modalFooter: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: 16,
    borderTopWidth: 1,
    borderTopColor: colors.lightGrey,
  },
  inputGroup: {
    marginBottom: 16,
  },
  inputLabel: {
    fontSize: 14,
    color: colors.mediumGrey,
    marginBottom: 8,
  },
  input: {
    borderWidth: 1,
    borderColor: colors.lightGrey,
    borderRadius: 4,
    padding: 8,
    fontSize: 16,
  },
  promotionTypeContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 8,
  },
  promotionTypeText: {
    fontSize: 16,
    color: colors.darkGrey,
  },
  promoCodeButton: {
    backgroundColor: colors.primary,
    borderRadius: 4,
    padding: 8,
  },
  promoCodeButtonText: {
    color: colors.white,
    fontWeight: 'bold',
  },
  dateContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  dateInput: {
    flex: 1,
  },
  dateSeparator: {
    marginHorizontal: 8,
    fontSize: 16,
    color: colors.darkGrey,
  },
  cancelButton: {
    marginRight: 16,
    padding: 8,
  },
  cancelButtonText: {
    color: colors.mediumGrey,
    fontWeight: 'bold',
  },
  saveButton: {
    backgroundColor: colors.primary,
    borderRadius: 4,
    padding: 8,
  },
  saveButtonText: {
    color: colors.white,
    fontWeight: 'bold',
  },
  mainItemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
  },
  mainItemInput: {
    flex: 1,
    marginRight: 8,
  },
  addButton: {
    backgroundColor: colors.primary,
    borderRadius: 4,
    padding: 8,
    alignItems: 'center',
    marginTop: 8,
  },
  addButtonText: {
    color: colors.white,
    fontWeight: 'bold',
  },
  modalOverlayPressable: {
    flex: 1,
  },
});