import React, { useState, useEffect, useContext } from 'react';
import { StyleSheet, Text, View, FlatList, Pressable, Modal, Image, TouchableOpacity, Dimensions, ScrollView, useWindowDimensions } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import colors from '../constants/colors';
import { getReviews } from '../services/api/orders';
import { AuthContext } from '../contexts/AuthContext';
import PlaceholderReviewCard from './PlaceholderReviewCard';
import ReviewMetrics from './ReviewMetrics';

const CARD_MARGIN = 8;

const ReviewCard = ({ review, onPress }) => {
  const categories = ['merchant', 'driver', 'service', 'uva'];
  const avgRating = calculateAverageRating(review.review);

  return (
    <Pressable style={styles.card} onPress={() => onPress(review)}>
      <View style={styles.cardHeader}>
        <View>
          <Text style={styles.userName}>{review.userName}</Text>
          <Text style={styles.date}>{new Date(review.createdAt).toLocaleDateString()}</Text>
        </View>
        <View style={styles.avgRatingContainer}>
          <Text style={styles.avgRating}>{avgRating.toFixed(1)}</Text>
          <Icon name="star" size={16} color={colors.primary} />
        </View>
      </View>
      {categories.map(category => (
        <View key={category} style={styles.categoryReview}>
          <Text style={[
            styles.categoryName,
            category === 'merchant' && styles.merchantCategory
          ]}>
            {category.charAt(0).toUpperCase() + category.slice(1)}
          </Text>
          <View style={styles.starContainer}>
            {[...Array(5)].map((_, i) => (
              <Icon
                key={i}
                name={i < review.review[category === 'merchant' ? 'restaurant' : category].star ? 'star' : 'star-border'}
                size={12}
                color={colors.primary}
              />
            ))}
          </View>
        </View>
      ))}
    </Pressable>
  );
};

const calculateAverageRating = (review) => {
  const validRatings = ['restaurant', 'driver', 'service', 'uva']
    .map(category => review[category].star)
    .filter(star => star > 0);
  
  return validRatings.length > 0 ? validRatings.reduce((a, b) => a + b) / validRatings.length : 0;
};

const ReviewDetail = ({ review, onClose }) => (
  <View style={styles.modalContent}>
    <View style={styles.modalHeader}>
      <Text style={styles.modalTitle}>Review Detail</Text>
      <Pressable onPress={onClose}>
        <Icon name="close" size={24} color={colors.darkGrey} />
      </Pressable>
    </View>
    <View style={styles.reviewDetail}>
      <Text style={styles.reviewerName}>{review.userName}</Text>
      <Text style={styles.reviewDate}>{new Date(review.createdAt).toLocaleString()}</Text>
      {['restaurant', 'driver', 'service', 'uva'].map(category => (
        <View key={category} style={styles.categoryReview}>
          <Text style={styles.categoryName}>{category.charAt(0).toUpperCase() + category.slice(1)}</Text>
          <View style={styles.starContainer}>
            {[...Array(5)].map((_, i) => (
              <Icon
                key={i}
                name={i < review.review[category].star ? 'star' : 'star-border'}
                size={24}
                color={colors.primary}
              />
            ))}
          </View>
          {review.review[category].text && (
            <Text style={styles.reviewText}>{review.review[category].text}</Text>
          )}
        </View>
      ))}
    </View>
  </View>
);

export default function Reviews() {
  const [reviews, setReviews] = useState([]);
  const [selectedReview, setSelectedReview] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { restaurant } = useContext(AuthContext);
  const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), 0, 1)); // Start of current year
  const [endDate, setEndDate] = useState(new Date()); // Today's date
  const { width } = useWindowDimensions();
  const [columnCount, setColumnCount] = useState(1);

  useEffect(() => {
    // Adjust column count based on screen width
    if (width < 600) setColumnCount(1);
    else if (width < 900) setColumnCount(2);
    else if (width < 1200) setColumnCount(3);
    else if (width < 1500) setColumnCount(4);
    else setColumnCount(5);
  }, [width]);

  useEffect(() => {
    if (restaurant) {
      fetchReviews();
    }
  }, [restaurant, startDate, endDate]);

  const fetchReviews = async () => {
    setIsLoading(true);
    try {
      const fetchedReviews = await getReviews(
        restaurant.id,
        startDate.toISOString(),
        endDate.toISOString()
      );

      const processedReviews = fetchedReviews
        .map(order => ({
          id: order.objectId,
          userName: order.user.full_name,
          createdAt: order.createdAt,
          review: order.review
        }))
        .filter(review => {
          // Check if at least one category has a non-zero star rating
          return ['restaurant', 'driver', 'service', 'uva'].some(
            category => review.review[category].star > 0
          );
        });

      setReviews(processedReviews);
    } catch (error) {
      console.error('Error fetching reviews:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleStartDateChange = (event) => {
    setStartDate(new Date(event.target.value));
  };

  const handleEndDateChange = (event) => {
    setEndDate(new Date(event.target.value));
  };

  const handleDateSubmit = () => {
    fetchReviews();
  };

  const handleReviewPress = (review) => {
    setSelectedReview(review);
    setIsModalVisible(true);
  };

  const renderReviewCard = ({ item }) => (
    <ReviewCard review={item} onPress={handleReviewPress} />
  );

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Text style={styles.title}>Customer Reviews</Text>
        <View style={styles.dateRangeContainer}>
          <input
            type="date"
            value={startDate.toISOString().split('T')[0]}
            onChange={handleStartDateChange}
            style={styles.dateInput}
          />
          <Text> - </Text>
          <input
            type="date"
            value={endDate.toISOString().split('T')[0]}
            onChange={handleEndDateChange}
            style={styles.dateInput}
          />
          <TouchableOpacity onPress={handleDateSubmit} style={styles.dateSubmitButton}>
            <Icon name="search" size={24} color={colors.primary} />
          </TouchableOpacity>
        </View>
      </View>
      {isLoading ? (
        <View style={styles.placeholderContainer}>
          <PlaceholderReviewCard />
          <PlaceholderReviewCard />
          <PlaceholderReviewCard />
        </View>
      ) : (
        <>
          <ReviewMetrics reviews={reviews} />
          <FlatList
            data={reviews}
            renderItem={renderReviewCard}
            keyExtractor={(item) => item.id.toString()}
            numColumns={columnCount}
            key={columnCount}
            contentContainerStyle={styles.gridContainer}
          />
        </>
      )}
      <Modal
        visible={isModalVisible}
        transparent={true}
        animationType="slide"
      >
        <View style={styles.modalOverlay}>
          {selectedReview && (
            <ReviewDetail
              review={selectedReview}
              onClose={() => setIsModalVisible(false)}
            />
          )}
        </View>
      </Modal>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: CARD_MARGIN,
    backgroundColor: colors.white,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 16,
    color: colors.darkGrey,
  },
  gridContainer: {
    flexGrow: 1,
  },
  card: {
    flex: 1,
    margin: CARD_MARGIN / 2,
    padding: 10,
    backgroundColor: colors.white,
    borderRadius: 8,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 3,
  },
  cardHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: 8,
  },
  avgRatingContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  avgRating: {
    fontSize: 16,
    fontWeight: 'bold',
    marginRight: 4,
    color: colors.primary,
  },
  userName: {
    fontWeight: 'bold',
    marginBottom: 4,
  },
  date: {
    fontSize: 12,
    color: colors.mediumGrey,
    marginBottom: 8,
  },
  categoryReview: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 4,
  },
  categoryName: {
    fontSize: 12,
  },
  merchantCategory: {
    fontWeight: 'bold',
    fontSize: 14,
    color: colors.primary,
  },
  starContainer: {
    flexDirection: 'row',
  },
  reviewText: {
    color: colors.darkGrey,
  },
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    backgroundColor: colors.white,
    borderRadius: 8,
    padding: 16,
    width: '80%',
    maxWidth: 500,
  },
  modalHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 16,
  },
  modalTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    color: colors.darkGrey,
  },
  reviewDetail: {
    alignItems: 'center',
  },
  reviewerName: {
    fontSize: 16,
    fontWeight: 'bold',
    color: colors.darkGrey,
  },
  reviewDate: {
    fontSize: 12,
    color: colors.mediumGrey,
    marginBottom: 8,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 16,
  },
  dateRangeContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  dateInput: {
    border: 'none',
    backgroundColor: 'transparent',
    color: colors.darkGrey,
    fontSize: 14,
  },
  dateSubmitButton: {
    marginLeft: 10,
    padding: 5,
  },
  placeholderContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    padding: CARD_MARGIN / 2,
  },
});