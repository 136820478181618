// Badge.js
import React from 'react';
import { View, Text, StyleSheet } from 'react-native';

export default function Badge({ backgroundColor, textColor, text, compact, customStyles }) {
  return (
    <View style={[styles.statusBadge, { backgroundColor, paddingVertical: compact ? 5 : 10 }, customStyles]}>
      <Text style={[styles.statusText, { color: textColor }]}>{text}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  statusBadge: {
    borderRadius: 5,
    padding: 15,
    marginTop: 10,
  },
  statusText: {
    fontWeight: 'bold',
    textAlign: 'center',
  },
});