import React, { useState, useEffect, useContext } from 'react';
import { ScrollView, View, StyleSheet, Text, Dimensions, Image, SafeAreaView } from 'react-native';
import { AuthContext } from '../contexts/AuthContext';
import OpeningHours from './OpeningHours';
import colors from '../constants/colors';

export default function Settings() {
  const { restaurant } = useContext(AuthContext) || {};
  const [isModified, setIsModified] = useState(false);
  const [schedule, setSchedule] = useState([]);

  useEffect(() => {
    if (restaurant && restaurant.get('schedule')) {
      const parsedSchedule = JSON.parse(restaurant.get('schedule'));
      const formattedSchedule = formatSchedule(parsedSchedule);
      setSchedule(formattedSchedule);
    }
  }, [restaurant]);

  const formatSchedule = (scheduleObj) => {
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return Object.entries(scheduleObj).map(([key, value]) => {
      const dayIndex = parseInt(key) - 1;
      // Check if value[0] exists and has start and end properties
      if (value && value[0] && value[0].start && value[0].end) {
        const { start, end } = value[0];
        return {
          day: daysOfWeek[dayIndex],
          open: formatTime(start),
          close: formatTime(end)
        };
      } else {
        // Return a default object if the expected structure is not present
        return {
          day: daysOfWeek[dayIndex],
          open: 'Closed',
          close: 'Closed'
        };
      }
    });
  };

  const formatTime = (time) => {
    const hours = time.slice(0, 2);
    const minutes = time.slice(2);
    return `${hours}:${minutes}`;
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (restaurant) {
      restaurant.set(name, value);
    }
    setIsModified(true);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (restaurant) {
      restaurant.save().then(() => {
        setIsModified(false);
      });
    }
  };

  const getData = (key) => {
    return restaurant ? restaurant.get(key) : '';
  };

  const getImageUrl = (key) => {
    if (restaurant && restaurant.get(key) && restaurant.get(key).url) {
      return restaurant.get(key).url();
    }
    return '';
  };

  return (
    <SafeAreaView style={styles.safeArea}>
      <ScrollView contentContainerStyle={styles.scrollViewContent}>
        <View style={styles.container}>
          <View style={styles.header}>
            <Text style={styles.headerText}>Merchant Details</Text>
          </View>
          <form onSubmit={handleSubmit} style={styles.form}>
            <View style={styles.section}>
              <View style={styles.inputContainer}>
                <Text style={styles.sectionTitle}>Merchant Logo</Text>
                <View style={styles.sectionInputs}>
                  <Image source={{ uri: getImageUrl('restaurant_logo') }} style={styles.image} />
                </View>
              </View>
              <View style={styles.inputContainer}>
                <Text style={styles.sectionTitle}>Merchant Cover Photo</Text>
                <View style={styles.sectionInputs}>
                  <Image source={{ uri: getImageUrl('image') }} style={styles.image} />
                </View>
              </View>
              <View style={styles.inputContainer}>
                <Text style={styles.sectionTitle}>Merchant Name</Text>
                <View style={styles.sectionInputs}>
                  <input name="restaurant_name" value={getData('restaurant_name')} onChange={handleInputChange} style={styles.input} />
                </View>
              </View>
              <View style={styles.inputContainer}>
                <Text style={styles.sectionTitle}>Contact Email</Text>
                <View style={styles.sectionInputs} >
                  <input name="contact_email" value={getData('contact_email')} onChange={handleInputChange} style={styles.input} />
                </View>
              </View>
              <View style={styles.inputContainer}>
                <Text style={styles.sectionTitle}>Contact Phone</Text>
                <View style={styles.sectionInputs} >
                  <input name="contact_phone" value={getData('contact_phone')} onChange={handleInputChange} style={styles.input} />
                </View>
              </View>
              <View style={styles.inputContainer}>
                <Text style={styles.sectionTitle}>About</Text>
                <View style={styles.sectionInputs} >
                  <textarea name="restaurant_about_us" value={getData('restaurant_about_us')} onChange={handleInputChange} style={styles.textarea} />
                </View>
              </View>
            </View>

            <View style={styles.section}>
              <Text style={styles.sectionTitle}>Bank Details</Text>
              <View style={styles.inputContainer}>
                <Text style={styles.sectionTitle}>Bank Account Number</Text>
                <View style={styles.sectionInputs}>
                  <input name="bankAccountNo" value={getData('bankAccountNo')} onChange={handleInputChange} style={styles.input} type="password" />
                </View>
              </View>
              <View style={styles.inputContainer}>
                <Text style={styles.sectionTitle}>Routing Number</Text>
                <View style={styles.sectionInputs}>
                  <input name="routingNo" value={getData('routingNo')} onChange={handleInputChange} style={styles.input} />
                </View>
              </View>
            </View>

            {schedule && schedule.length > 0 && (
              <OpeningHours
                schedule={schedule}
                handleScheduleChange={(newSchedule) => {
                  setSchedule(newSchedule);
                  setIsModified(true);
                }}
              />
            )}

            <View style={styles.inputContainer}>
              <Text style={styles.sectionTitle}>Account Manager</Text>
              <View style={styles.sectionInputs}>
                <Text>{restaurant.get('accountManager') ? restaurant.get('accountManager').get('full_name') : 'No account manager assigned'}</Text>
              </View>
            </View>

            <button type="submit" disabled={!isModified} style={styles.button}>Save Changes</button>
          </form>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const windowHeight = Dimensions.get('window').height;

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    backgroundColor: colors.background, // Use your app's background color
  },
  scrollViewContent: {
    flexGrow: 1,
  },
  container: {
    flex: 1,
    padding: 20,
    minHeight: windowHeight,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 16,
    alignItems: 'center',
  },
  headerText: {
    fontSize: 25,
    fontWeight: 'bold',
    color: colors.darkGrey,
    marginHorizontal: 10,
  },
  form: {
    flex: 1,
  },
  section: {
    marginBottom: 20,
    width: '80%',
  },
  image: {
    width: 100,
    height: 100,
    marginBottom: 10,
  },
  input: {
    height: 20,
    borderColor: colors.lightGrey,
    borderWidth: 1,
    borderRadius: 5,
    margin: 10,
    padding: 10,
  },
  textarea: {
    height: 100,
    borderColor: colors.lightGrey,
    borderWidth: 1,
    borderRadius: 5,
    margin: 10,
    padding: 10,
  },
  inputContainer: {
    marginBottom: 20,
    flexDirection: 'row',
    width: '100%',
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    color: colors.darkGrey,
    marginBottom: 10,
    flex: 1,
  },
  sectionInputs: {
    flex: 1,
  },
  checkboxContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  checkboxLabel: {
    marginLeft: 10,
  },
  button: {
    backgroundColor: colors.darkGrey,
    color: '#fff',
    padding: 10,
    borderRadius: 5,
    textAlign: 'center',
  },
});