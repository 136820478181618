import React, { useState, useEffect, useContext } from 'react';
import { View, Text, StyleSheet, ActivityIndicator, TouchableOpacity, Dimensions, ScrollView } from 'react-native';
import { LineChart, YAxis, XAxis } from 'react-native-svg-charts';
import { G, Line } from 'react-native-svg';
import colors from '../../constants/colors';
import { AuthContext } from '../../contexts/AuthContext';
import { getSalesData } from '../../services/api/orders';
import moment from 'moment';
import { fonts, fontSizes } from '../../constants/typography';

const { width } = Dimensions.get('window');
const isMobile = width < 768;

export default function SalesOverview({ startDate, endDate }) {
  const [data, setData] = useState([]);
  const [previousPeriodData, setPreviousPeriodData] = useState([]);
  const [totalSales, setTotalSales] = useState(0);
  const [changePercentage, setChangePercentage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingPrevious, setIsLoadingPrevious] = useState(true);
  const [viewPeriod, setViewPeriod] = useState('day');
  const { restaurant } = useContext(AuthContext);

  useEffect(() => {
    if (restaurant && startDate && endDate) {
      fetchSalesData();
    }
  }, [restaurant, startDate, endDate, viewPeriod]);

  useEffect(() => {
    if (!isLoading && totalSales > 0) {
      fetchPreviousPeriodData();
    }
  }, [isLoading, totalSales]);

  const fetchSalesData = async () => {
    try {
      setIsLoading(true);

      // export const getSalesData = async (restaurantId, startDate, endDate, fields = ['date', 'total']) => {
      const salesData = await getSalesData(restaurant.id, startDate, endDate, [
        'date',
        'total',
        'subtotals',
        'restaurantSubtotals',
        'subtotalData',
        'createdAt'
      ]);

      const processedData = processData(salesData, viewPeriod);

      setData(processedData);

      const total = processedData.reduce((sum, item) => sum + item.total, 0);
      setTotalSales(total);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching sales data:', error);
      setIsLoading(false);
    }
  };

  const fetchPreviousPeriodData = async () => {
    try {
      setIsLoadingPrevious(true);

      const startMoment = moment(startDate);
      const endMoment = moment(endDate);
      const duration = moment.duration(endMoment.diff(startMoment));

      const previousStartDate = startMoment.clone().subtract(duration);
      const previousEndDate = endMoment.clone().subtract(duration);

      const previousSalesData = await getSalesData(
        restaurant.id,
        previousStartDate.toDate(),
        previousEndDate.toDate(),
        ['date', 'total', 'subtotals', 'restaurantSubtotals', 'subtotalData', 'createdAt']
      );

      const processedPreviousData = processData(previousSalesData, viewPeriod);
      setPreviousPeriodData(processedPreviousData);

      const previousTotal = processedPreviousData.reduce((sum, item) => sum + item.total, 0);

      // Calculate change percentage
      if (previousTotal > 0) {
        const changePercentage = ((totalSales - previousTotal) / previousTotal * 100).toFixed(1);
        setChangePercentage(changePercentage);
      } else {
        setChangePercentage('N/A');
      }

      setIsLoadingPrevious(false);
    } catch (error) {
      console.error('Error fetching previous period sales data:', error);
      setIsLoadingPrevious(false);
    }
  };

  const processData = (salesData, period) => {
    switch (period) {
      case 'day':
        return aggregateData(salesData, (date) => moment(date).format('YYYY-MM-DD'));
      case 'week':
        return aggregateData(salesData, (date) => {
          const momentDate = moment(date);
          return momentDate.year() + '-W' + momentDate.isoWeek();
        });
      case 'month':
        return aggregateData(salesData, (date) => moment(date).format('YYYY-MM'));
      case 'quarter':
        return aggregateData(salesData, (date) => {
          const momentDate = moment(date);
          return momentDate.year() + '-Q' + momentDate.quarter();
        });
      case 'year':
        return aggregateData(salesData, (date) => moment(date).format('YYYY'));
      default:
        return aggregateData(salesData, (date) => moment(date).format('YYYY-MM-DD'));
    }
  };

  const aggregateData = (data, getKey) => {
    const aggregated = data.reduce((acc, item) => {
      const key = getKey(item.date);
      if (!acc[key]) {
        acc[key] = { date: key, total: 0 };
      }
      acc[key].total += item.total;
      return acc;
    }, {});
    return Object.values(aggregated).sort((a, b) => a.date.localeCompare(b.date));
  };

  const CustomGrid = ({ x, y, data, ticks }) => (
    <G>
      {ticks.map(tick => (
        <Line
          key={tick}
          x1={'0%'}
          x2={'100%'}
          y1={y(tick)}
          y2={y(tick)}
          stroke={'rgba(0,0,0,0.1)'}
        />
      ))}
      {/* {data.map((_, index) => (
        <Line
          key={index}
          y1={'0%'}
          y2={'100%'}
          x1={x(index)}
          x2={x(index)}
          stroke={'rgba(0,0,0,0.1)'}
        />
      ))} */}
    </G>
  );

  const chartWidth = Math.max(width - 80, data.length * 50); // Ajusta este valor según tus necesidades

  if (isLoading) {
    return (
      <View style={[styles.container, styles.loadingContainer]}>
        <ActivityIndicator size="large" color={colors.primary} />
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Text style={styles.title}>Sales</Text>
        <View style={styles.periodSelector}>
          {['day', 'week', 'month', 'year'].map((period, index) => (
            <TouchableOpacity
              key={period}
              onPress={() => setViewPeriod(period)}
              style={[
                styles.periodButton,
                viewPeriod === period && styles.activePeriodButton,
                index !== 3 && styles.borderRight
              ]}
            >
              <Text style={[
                styles.periodButtonText,
                viewPeriod === period && styles.activePeriodButtonText,
                {
                  fontFamily: 'Roboto-Medium, Roboto, sans-serif',
                  fontWeight: '900',
                }
              ]}>
                {period.charAt(0).toUpperCase() + period.slice(1)}
              </Text>
            </TouchableOpacity>
          ))}
        </View>
      </View>
      <Text style={styles.salesAmount}>${totalSales.toFixed(2)}</Text>
      {!isLoadingPrevious && (
        <Text style={[styles.salesChange, { color: changePercentage >= 0 ? colors.success : colors.bad }]}>
          {changePercentage >= 0 ? '+' : ''}{changePercentage}% {changePercentage >= 0 ? '↑' : '↓'}
        </Text>
      )}
      <View style={styles.chartWrapper}>
        <YAxis
          data={data.map(item => item.total)}
          contentInset={{ top: 20, bottom: 20 }}
          svg={{ fontSize: 14, fill: colors.black, fontFamily: 'Roboto-Medium, Roboto, sans-serif' }}
          numberOfTicks={5}
          formatLabel={(value) => `$${value}`}
          style={[styles.yAxis, { fontFamily: fonts.medium }]}
        />
        <ScrollView horizontal showsHorizontalScrollIndicator={false}>
          <View style={[styles.chartContainer, { width: chartWidth }]}>
            <LineChart
              style={{ flex: 1, marginLeft: 10 }}
              data={data.map(item => item.total)}
              svg={{ stroke: colors.primary, strokeWidth: 2 }}
              contentInset={{ top: 20, bottom: 20, left: 10, right: 10 }}
            >
              <CustomGrid />
            </LineChart>
            <XAxis
              style={styles.xAxis}
              data={data}
              formatLabel={(_, index) => {
                const date = data[index].date;
                switch (viewPeriod) {
                  case 'day':
                    return moment(date).format('MM/DD');
                  case 'week':
                    return 'W' + date.split('-W')[1];
                  case 'month':
                    return moment(date).format('MMM');
                  case 'quarter':
                    return 'Q' + date.split('-Q')[1];
                  case 'year':
                    return date;
                  default:
                    return moment(date).format('MM/DD');
                }
              }}
              contentInset={{ left: 10, right: 10 }}
              svg={{ fontSize: 14, fill: colors.black, fontFamily: 'Roboto-Medium, Roboto, sans-serif' }}
            />
          </View>
        </ScrollView>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    padding: 20,
    marginBottom: 20,
    zIndex: 0,
    width: isMobile ? '100%' : '73%',
    borderRadius: 10,
    shadowColor: colors.darkGrey,
    shadowOffset: { width: 5, height: 5 },
    shadowOpacity: 0.1,
    shadowRadius: 5,
    elevation: 5,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
  title: {
    fontSize: fontSizes.large,
    // fontFamily: fonts.bold,
    color: colors.darkGrey,
  },
  salesAmount: {
    fontSize: fontSizes.extraLarge,
    fontFamily: 'Roboto-Bold, Roboto, sans-serif',
    color: colors.darkGrey,
    fontWeight: '900',
  },
  salesChange: {
    fontSize: fontSizes.medium,
    // fontFamily: fonts.bold,
    marginBottom: 10,
  },
  chartContainer: {
    height: 250,
    // flexDirection: 'row',
    marginTop: 10,
  },
  loadingContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  periodSelector: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop: 10,
    borderRadius: 5,
    backgroundColor: colors.white,
    borderWidth: 1,
    borderColor: '#132632',
  },
  periodButton: {
    paddingHorizontal: 10,
    paddingVertical: 10,
  },
  activePeriodButton: {},
  periodButtonText: {
    color: '#122632',
    fontSize: fontSizes.small,
    // fontFamily: fonts.medium,
  },
  activePeriodButtonText: {
    color: colors.primary,
  },
  borderRight: {
    borderRightWidth: 1,
    borderRightColor: '#122632',
  },
  chartWrapper: {
    flexDirection: 'row',
    height: 250,
    marginTop: 10,
  },
  yAxis: {
    width: 50, // Ajusta este valor según sea necesario
  },
  xAxis: {
    marginHorizontal: -10,
    height: 30,
    fontFamily: fonts.medium,
  },
});