import React, { useState, useEffect, useContext } from 'react';
import { View, Text, StyleSheet, ActivityIndicator, Dimensions, TouchableOpacity } from 'react-native';
import { Tooltip } from '@rneui/themed';
import AntDesign from '@expo/vector-icons/AntDesign';
import colors from '../../constants/colors';
import { getOrders } from '../../services/api/orders';
import { AuthContext } from '../../contexts/AuthContext';
import MetricCard from './MetricCard';
const { width } = Dimensions.get('window');

const isMobile = width < 768;

// const TaxBreakdownModal = ({ isVisible, onClose, taxData }) => (
//   <Modal
//     animationType="slide"
//     transparent={true}
//     visible={isVisible}
//     onRequestClose={onClose}
//   >
//     <View style={styles.modalOverlay}>
//       <View style={styles.modalContent}>
//         <Text style={styles.modalTitle}>Tax Breakdown</Text>
//         <View style={styles.taxItem}>
//           <Text style={styles.taxItemName}>Municipal Tax</Text>
//           <Text style={styles.taxItemValue}>${taxData.municipality.toFixed(2)}</Text>
//         </View>
//         <View style={styles.taxItem}>
//           <Text style={styles.taxItemName}>State Tax 6%</Text>
//           <Text style={styles.taxItemValue}>${taxData.stateTaxes6.toFixed(2)}</Text>
//         </View>
//         <View style={styles.taxItem}>
//           <Text style={styles.taxItemName}>State Tax 10.5%</Text>
//           <Text style={styles.taxItemValue}>${taxData.stateTaxes105.toFixed(2)}</Text>
//         </View>
//         <View style={styles.taxItem}>
//           <Text style={styles.taxItemName}>State Tax Other</Text>
//           <Text style={styles.taxItemValue}>${taxData.stateTaxesOther.toFixed(2)}</Text>
//         </View>
//         <View style={styles.taxItem}>
//           <Text style={styles.taxItemName}>Total Tax</Text>
//           <Text style={styles.taxItemValue}>${taxData.total.toFixed(2)}</Text>
//         </View>
//         <TouchableOpacity style={styles.closeButton} onPress={onClose}>
//           <Text style={styles.closeButtonText}>Close</Text>
//         </TouchableOpacity>
//       </View>
//     </View>
//   </Modal>
// );

// const MetricCard = ({ title, value, change, isLoading, hasBorder, onInfoPress }) => (
//   <View style={[styles.card, hasBorder && styles.borderRight]}>
//     <View style={styles.cardTitleContainer}>
//       <Text style={styles.cardTitle}>{title}</Text>
//       {title === 'Total Taxes' && (
//         <Tooltip
//           visible={open}
//           onOpen={() => setOpen(true)}
//           onClose={() => setOpen(false)}
//           popover={<Text style={{ color: "#fff" }}>Tooltip text</Text>}
//         >
//           <AntDesign name="infocirlce" size={18} color="black" />
//         </Tooltip>
//       )}
//     </View>
//     {
//       isLoading ? (
//         <ActivityIndicator size="small" color={colors.primary} />
//       ) : (
//         <>
//           <Text style={styles.cardValue}>{value}</Text>
//           {change !== 'N/A' && (
//             <Text style={[styles.cardChange, { color: parseFloat(change) >= 0 ? colors.success : colors.bad }]}>
//               {parseFloat(change) >= 0 ? '+' : ''}{change}% {parseFloat(change) >= 0 ? '↑' : '↓'}
//             </Text>
//           )}
//         </>
//       )
//     }
//   </View>
// );

export default function TotalMetrics({ startDate, endDate }) {
  const [metrics, setMetrics] = useState({
    totalSales: 0,
    totalTaxes: 0,
    totalRevenueShare: 0,
    totalDeposit: 0,
    averageOrderPrice: 0,
  });
  const [previousMetrics, setPreviousMetrics] = useState({});
  const [changePercentages, setChangePercentages] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingPrevious, setIsLoadingPrevious] = useState(true);
  const { restaurant } = useContext(AuthContext);
  // const [isModalVisible, setIsModalVisible] = useState(false);
  // const [taxBreakdown, setTaxBreakdown] = useState({
  //   municipality: 0,
  //   stateTaxes6: 0,
  //   stateTaxes105: 0,
  //   stateTaxesOther: 0,
  //   total: 0,
  // });

  useEffect(() => {
    if (restaurant && startDate && endDate) {
      fetchMetrics();
    }
  }, [startDate, endDate, restaurant]);

  useEffect(() => {
    if (!isLoading && metrics.totalSales > 0) {
      fetchPreviousPeriodMetrics();
    }
  }, [isLoading, metrics]);

  const fetchMetrics = async () => {
    try {
      setIsLoading(true);
      const orders = await getOrders(restaurant.id, startDate.toISOString(), endDate.toISOString(), [
        'objectId',
        'total',
        'taxData',
        'earnings',
        'subtotals',
        'restaurantSubtotals',
        'subtotalData',
        'dishes',
        'revShare'
      ], 1000, ['DELIVERED']);

      const calculatedMetrics = calculateMetrics(orders);

      // const taxBreakdown = calculateTaxBreakdown(orders);
      // setTaxBreakdown(taxBreakdown);

      setMetrics(calculatedMetrics);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching metrics:', error);
      setIsLoading(false);
    }
  };

  const fetchPreviousPeriodMetrics = async () => {
    try {
      setIsLoadingPrevious(true);
      const periodLength = endDate.getTime() - startDate.getTime();
      const previousStartDate = new Date(startDate.getTime() - periodLength);
      const previousEndDate = new Date(endDate.getTime() - periodLength);

      const previousOrders = await getOrders(restaurant.id, previousStartDate.toISOString(), previousEndDate.toISOString(), [
        'objectId',
        'total',
        'taxData',
        'earnings',
        'subtotals',
        'restaurantSubtotals',
        'subtotalData',
        'dishes',
        'revShare'
      ], 1000, ['DELIVERED']);

      const calculatedPreviousMetrics = calculateMetrics(previousOrders);
      setPreviousMetrics(calculatedPreviousMetrics);

      // Calculate change percentages
      const changes = {};
      for (const key in metrics) {
        if (calculatedPreviousMetrics[key] > 0) {
          changes[key] = ((metrics[key] - calculatedPreviousMetrics[key]) / calculatedPreviousMetrics[key] * 100).toFixed(1);
        } else {
          changes[key] = 'N/A';
        }
      }
      setChangePercentages(changes);

      setIsLoadingPrevious(false);
    } catch (error) {
      console.error('Error fetching previous period metrics:', error);
      setIsLoadingPrevious(false);
    }
  };

  const calculateMetrics = (orders) => {
    let totalSales = 0;
    let totalTaxes = 0;
    let totalRevenueShare = 0;
    let totalDeposit = 0;
    let totalOrderPrice = 0;

    orders.forEach(order => {
      totalSales += order.restaurantSubtotals;
      totalTaxes += order.taxData.foodTaxes.total;
      totalDeposit += order.subtotalData.totalRestaurantSubtotal - order.revShare;
      totalOrderPrice += order.subtotalData.totalRestaurantSubtotal;
      totalRevenueShare += order.revShare;
    });

    const averageOrderPrice = orders.length > 0 ? totalOrderPrice / orders.length : 0;

    return {
      totalSales: parseFloat(totalSales.toFixed(2)),
      totalTaxes: parseFloat(totalTaxes.toFixed(2)),
      totalRevenueShare: parseFloat(totalRevenueShare.toFixed(2)),
      totalDeposit: parseFloat(totalDeposit.toFixed(2)),
      averageOrderPrice: parseFloat(averageOrderPrice.toFixed(2)),
    };
  };

  // const calculateTaxBreakdown = (orders) => {
  //   let taxBreakdown = {
  //     municipality: 0,
  //     stateTaxes6: 0,
  //     stateTaxes105: 0,
  //     stateTaxesOther: 0,
  //     total: 0,
  //   };

  //   orders.forEach(order => {
  //     taxBreakdown.municipality += order.taxData.foodTaxes.municipality.total;
  //     taxBreakdown.stateTaxes6 += order.taxData.foodTaxes.state.stateTaxes6;
  //     taxBreakdown.stateTaxes105 += order.taxData.foodTaxes.state.stateTaxes105;
  //     taxBreakdown.stateTaxesOther += order.taxData.foodTaxes.state.stateTaxesOther;
  //     taxBreakdown.total += order.taxData.foodTaxes.total;
  //   });

  //   return taxBreakdown;
  // };

  const handleInfoPress = () => {
    setIsModalVisible(true);
  };

  return !isMobile ? (
    <>
      <View style={styles.container}>
        <MetricCard
          title="Total Sales"
          value={`$${metrics.totalSales}`}
          change={changePercentages.totalSales}
          isLoading={isLoading}
          hasBorder={true}
        />
        <MetricCard
          title="Total Taxes"
          value={`$${metrics.totalTaxes}`}
          change='N/A'
          isLoading={isLoading}
          hasBorder={true}
          onInfoPress={handleInfoPress}
        />
        <MetricCard
          title="Total Revenue Share"
          value={`$${metrics.totalRevenueShare}`}
          change='N/A'
          isLoading={isLoading}
          hasBorder={true}
        />
        <MetricCard
          title="Total Deposit"
          value={`$${metrics.totalDeposit}`}
          change={changePercentages.totalDeposit}
          isLoading={isLoading}
          hasBorder={true}
        />
        <MetricCard
          title="Average Order Price"
          value={`$${metrics.averageOrderPrice}`}
          change={changePercentages.averageOrderPrice}
          isLoading={isLoading}
          hasBorder={false}
        />
      </View>
      {/* <TaxBreakdownModal
        isVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        taxData={taxBreakdown}
      /> */}
    </>
  ) : (
    <>
      <View>
        <View style={styles.container}>
          <MetricCard
            title="Total Sales"
            value={`$${metrics.totalSales}`}
            change={changePercentages.totalSales}
            isLoading={isLoading}
            hasBorder={true}
          />
          <MetricCard
            title="Total Taxes"
            value={`$${metrics.totalTaxes}`}
            change='N/A'
            isLoading={isLoading}
            hasBorder={false}
            onInfoPress={handleInfoPress}
          />
        </View>
        <View style={styles.container}>
          <MetricCard
            title="Total Revenue Share"
            value={`$${metrics.totalRevenueShare}`}
            change='N/A'
            isLoading={isLoading}
            hasBorder={true}
          />
          <MetricCard
            title="Total Deposit"
            value={`$${metrics.totalDeposit}`}
            change={changePercentages.totalDeposit}
            isLoading={isLoading}
            hasBorder={false}
          />
        </View>

        <View style={styles.container}>
          <MetricCard
            title="Average Order Price"
            value={`$${metrics.averageOrderPrice}`}
            change={changePercentages.averageOrderPrice}
            isLoading={isLoading}
            hasBorder={false}
          />
        </View>
      </View>
      {/* <TaxBreakdownModal
        isVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        taxData={taxBreakdown}
      /> */}
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
    backgroundColor: colors.white,
    padding: 15,
    borderRadius: 10,
    shadowColor: colors.darkGrey,
    shadowOffset: { width: 5, height: 5 },
    shadowOpacity: 0.1,
    shadowRadius: 5,
    elevation: 5,
  },
  card: {
    backgroundColor: colors.white,
    padding: 5,
    flex: 1,
    marginHorizontal: 5,
  },
  cardTitle: {
    fontSize: 14,
    color: '#122632',
    marginBottom: 5,
    marginRight: 5,
  },
  cardValue: {
    fontSize: 30,
    fontWeight: 'bold',
    color: colors.darkGrey,
    marginBottom: 5,
  },
  cardChange: {
    fontSize: 14,
    fontWeight: 700,
  },
  borderRight: {
    borderRightWidth: 1,
    borderRightColor: 'rgba(0, 0, 0, 0.2)',
  },
  cardTitleContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 10,
    width: '30%',
    maxHeight: '80%',
  },
  modalTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  taxItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 5,
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(0, 0, 0, 0.2)',
    padding: 5,
  },
  taxItemName: {
    fontSize: 16,
  },
  taxItemValue: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  closeButton: {
    marginTop: 20,
    padding: 10,
    backgroundColor: colors.primary,
    borderRadius: 5,
    alignItems: 'center',
  },
  closeButtonText: {
    color: 'white',
    fontSize: 16,
  },
});