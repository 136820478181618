import React, { createContext, useState, useEffect } from 'react';
import moment from 'moment';

export const DateContext = createContext();

export const DateProvider = ({ children }) => {
  const getStartOfWeek = (weeksAgo = 1) => {
    const now = new Date();
    const today = now.getDay();
    const daysToMonday = today + (today === 0 ? 6 : -1) + (7 * weeksAgo);
    const targetMonday = new Date(now.setDate(now.getDate() - daysToMonday));
    targetMonday.setHours(0, 0, 0, 0);
    return targetMonday;
  };

  const getEndOfToday = () => {
    const now = new Date();
    now.setHours(23, 59, 59, 999);
    return now;
  };

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    const savedStartDate = localStorage.getItem('startDate');
    const savedEndDate = localStorage.getItem('endDate');

    if (savedStartDate && savedEndDate) {
      const parsedStartDate = moment(savedStartDate).startOf('day').toDate();
      const parsedEndDate = moment(savedEndDate).endOf('day').toDate();

      setStartDate(parsedStartDate);
      setEndDate(parsedEndDate);
    } else {
      const defaultStartDate = getStartOfWeek();
      const defaultEndDate = getEndOfToday();

      // setStartDate(defaultStartDate);
      // setEndDate(defaultEndDate);

      // localStorage.setItem('startDate', defaultStartDate);
      // localStorage.setItem('endDate', defaultEndDate);
      updateDates(defaultStartDate, defaultEndDate);
    }
  }, []);

  // useEffect(() => {
  //   if (startDate && endDate) {
  //     localStorage.setItem('startDate', startDate.toISOString());
  //     localStorage.setItem('endDate', endDate.toISOString());
  //   }
  // }, [startDate, endDate]);

  const updateDates = (start, end) => {
    const adjustedStartDate = moment(start).startOf('day');
    const adjustedEndDate = moment(end).endOf('day');
    // const adjustedStartDate = moment(start).format('YYYY-MM-DD');
    // const adjustedEndDate = moment(end).format('YYYY-MM-DD');

    setStartDate(adjustedStartDate.toDate());
    setEndDate(adjustedEndDate.toDate());

    localStorage.setItem('startDate', adjustedStartDate.format('YYYY-MM-DD'));
    localStorage.setItem('endDate', adjustedEndDate.format('YYYY-MM-DD'));
  };

  const handleClearDates = () => {
    const defaultStartDate = getStartOfWeek();
    const defaultEndDate = getEndOfToday();
    updateDates(defaultStartDate, defaultEndDate);
  };

  return (
    <DateContext.Provider value={{ startDate, endDate, updateDates, handleClearDates }}>
      {children}
    </DateContext.Provider>
  );
};
