// AuthContext.js
import React, { useState, createContext, useEffect } from 'react';
import { getRestaurantsForUser } from '../services/api/restaurant';
import { requestGetRestaurant } from '../services/api/auth';
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [restaurant, setRestaurant] = useState(null);
  const [pubnubMessage, setPubnubMessage] = useState(null); // Add this line
  const [restaurants, setRestaurants] = useState([]);
  const [selectedRestaurant, setSelectedRestaurant] = useState('');

  useEffect(() => {
    if (user) {
      getRestaurantsForUser(user.id).then((restaurants) => {
        if (restaurants.length === 0) {
          requestGetRestaurant(user.get('restaurant').id).then((restaurant) => {
            setRestaurant(restaurant);
          });
        } else {
          setRestaurants(restaurants);
          setSelectedRestaurant(restaurants[0].id);
          setRestaurant(restaurants[0]);
        }
      });
    }
  }, [user]);

  useEffect(() => {
    if (selectedRestaurant) {
      setRestaurant(restaurants.find(restaurant => restaurant.id === selectedRestaurant));
    }
  }, [selectedRestaurant]);

  useEffect(() => {
    if (restaurant) {
      // localStorage.setItem('restaurant', JSON.stringify(restaurant));
    }
  }, [restaurant]);

  return (
    <AuthContext.Provider value={{
      user,
      setUser,
      restaurant,
      setRestaurant,
      pubnubMessage,
      setPubnubMessage,
      restaurants,
      setRestaurants,
      selectedRestaurant,
      setSelectedRestaurant
    }}>
      {children}
    </AuthContext.Provider>
  );
};