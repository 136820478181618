import React, { useState, useEffect, useContext } from 'react';
import { StyleSheet, Text, View, TextInput, Pressable, FlatList, Modal } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import { AuthContext } from '../contexts/AuthContext';
import colors from '../constants/colors';

const PaymentHistoryItem = ({ payment }) => (
  <View style={styles.paymentItem}>
    <Text style={styles.paymentDate}>{new Date(payment.date).toLocaleDateString()}</Text>
    <Text style={styles.paymentAmount}>${payment.amount.toFixed(2)}</Text>
    <Text style={styles.paymentStatus}>{payment.status}</Text>
  </View>
);

export default function BankDetails() {
  const { restaurant } = useContext(AuthContext);
  const [bankName, setBankName] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [routingNumber, setRoutingNumber] = useState('');
  const [accountHolderName, setAccountHolderName] = useState('');
  const [payments, setPayments] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    // Fetch bank details and payment history from API
    // For now, we'll use dummy data
    setBankName('Sample Bank');
    setAccountNumber('****1234');
    setRoutingNumber('021000021');
    setAccountHolderName('John Doe');
    setPayments([
      { id: 1, date: '2023-05-01', amount: 1000, status: 'Completed' },
      { id: 2, date: '2023-05-15', amount: 1500, status: 'Pending' },
      // Add more dummy payments...
    ]);
  }, []);

  const handleSave = () => {
    // Save bank details to API
    console.log('Saving bank details:', { bankName, accountNumber, routingNumber, accountHolderName });
    // Show success message
  };

  const handleFilter = () => {
    // Filter payments based on date range
    console.log('Filtering payments:', { startDate, endDate });
    setIsModalVisible(false);
    // Update payments list with filtered results
  };

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Bank Details</Text>
      <View style={styles.formContainer}>
        <TextInput
          style={styles.input}
          placeholder="Bank Name"
          value={bankName}
          onChangeText={setBankName}
        />
        <TextInput
          style={styles.input}
          placeholder="Account Number"
          value={accountNumber}
          onChangeText={setAccountNumber}
          keyboardType="numeric"
        />
        <TextInput
          style={styles.input}
          placeholder="Routing Number"
          value={routingNumber}
          onChangeText={setRoutingNumber}
          keyboardType="numeric"
        />
        <TextInput
          style={styles.input}
          placeholder="Account Holder Name"
          value={accountHolderName}
          onChangeText={setAccountHolderName}
        />
        <Pressable style={styles.saveButton} onPress={handleSave}>
          <Text style={styles.saveButtonText}>Save Bank Details</Text>
        </Pressable>
      </View>

      <View style={styles.paymentHistoryContainer}>
        <Text style={styles.subtitle}>Payment History</Text>
        <Pressable style={styles.filterButton} onPress={() => setIsModalVisible(true)}>
          <Icon name="filter-list" size={24} color={colors.primary} />
          <Text style={styles.filterButtonText}>Filter</Text>
        </Pressable>
      </View>

      <FlatList
        data={payments}
        renderItem={({ item }) => <PaymentHistoryItem payment={item} />}
        keyExtractor={(item) => item.id.toString()}
      />

      <Modal
        visible={isModalVisible}
        transparent={true}
        animationType="slide"
      >
        <View style={styles.modalOverlay}>
          <View style={styles.modalContent}>
            <Text style={styles.modalTitle}>Filter Payments</Text>
            <TextInput
              style={styles.input}
              placeholder="Start Date (YYYY-MM-DD)"
              value={startDate}
              onChangeText={setStartDate}
            />
            <TextInput
              style={styles.input}
              placeholder="End Date (YYYY-MM-DD)"
              value={endDate}
              onChangeText={setEndDate}
            />
            <Pressable style={styles.filterButton} onPress={handleFilter}>
              <Text style={styles.filterButtonText}>Apply Filter</Text>
            </Pressable>
            <Pressable style={styles.closeButton} onPress={() => setIsModalVisible(false)}>
              <Icon name="close" size={24} color={colors.darkGrey} />
            </Pressable>
          </View>
        </View>
      </Modal>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    backgroundColor: colors.white,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 16,
    color: colors.darkGrey,
  },
  subtitle: {
    fontSize: 20,
    fontWeight: 'bold',
    marginTop: 24,
    marginBottom: 16,
    color: colors.darkGrey,
  },
  formContainer: {
    marginBottom: 24,
  },
  input: {
    borderWidth: 1,
    borderColor: colors.lightGrey,
    borderRadius: 4,
    padding: 8,
    marginBottom: 16,
  },
  saveButton: {
    backgroundColor: colors.primary,
    padding: 12,
    borderRadius: 4,
    alignItems: 'center',
  },
  saveButtonText: {
    color: colors.white,
    fontWeight: 'bold',
  },
  paymentHistoryContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 16,
  },
  filterButton: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  filterButtonText: {
    color: colors.primary,
    marginLeft: 8,
  },
  paymentItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 16,
    borderBottomWidth: 1,
    borderBottomColor: colors.lightGrey,
  },
  paymentDate: {
    flex: 1,
  },
  paymentAmount: {
    flex: 1,
    textAlign: 'center',
  },
  paymentStatus: {
    flex: 1,
    textAlign: 'right',
  },
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    backgroundColor: colors.white,
    borderRadius: 8,
    padding: 16,
    width: '80%',
    maxWidth: 400,
  },
  modalTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 16,
    color: colors.darkGrey,
  },
  closeButton: {
    position: 'absolute',
    top: 8,
    right: 8,
  },
});
